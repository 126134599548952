import React from "react"
import Confirm from "./Confirm"
import WelcomeModal from "./WelcomeModal"

export interface ModalContextProps {
    title?: string
    content?: React.ReactNode
    onOk?: (() => void) | (() => Promise<void>)
    onCancel?: () => void
}

export interface ModalProps extends ModalContextProps {
    open: boolean
    close: () => void
}

export enum ModalsKeys {
    Confirm = "Confirm",
    None = "None",
    WelcomeMessage = "WelcomeMessage",
}

export default { Confirm, WelcomeMessage: WelcomeModal, None: null } as {
    [key in ModalsKeys]: React.FC<ModalProps> | null
}
