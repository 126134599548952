import { useCallback, useContext } from "react"

import { ModalContext } from "@/components/ModalProvider"
import { ModalContextProps, ModalsKeys } from "@/components/ModalProvider/Modals"
import { t } from "i18next"

const defaultConfirmProps: ModalContextProps = {
    title: "Confirm",
}

export const useModal = () => {
    const { setModal, setProps } = useContext(ModalContext)

    const confirm = useCallback((message: string, props: ModalContextProps) => {
        // @ts-ignore
        defaultConfirmProps.title = t(defaultConfirmProps.title)
        setProps({ ...defaultConfirmProps, ...props, content: message })
        setModal(ModalsKeys.Confirm)
    }, [])

    const welcomeMessage = useCallback((title: string, message: string) => {
        setProps({ title, content: message })
        setModal(ModalsKeys.WelcomeMessage)
    }, [])

    return {
        confirm,
        welcomeMessage,
    }
}
