import React, { ChangeEvent } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
    setAnswerGenerationPromptTemplate,
    setAssistantType,
    setCitationsHighlightMode,
    setCognitiveSearchQueryGenerationPromptTemplate,
    setDocsSearchThreshold,
    setImproveSearchQueryMode,
    setModel,
    setTokensUsageMode,
    setModelProvider,
    setPdfCitationDisplayMode,
    setSearchApproach,
    setShowWelcomeMessage,
    setTemperatureCount,
    setUseSuggestFollowupQuestions,
    setPreselectContexFilesMode,
    setDocsPreselectSearchThreshold,
} from "@/slices/chatSlice"
import { useLocalStorage } from "usehooks-ts"
import {
    AssistantTypes,
    PreselectContextFilesModes,
    ImproveSearchQueryModes,
    ModelVersions,
    SearchApproaches,
    TokensUsageModes,
} from "@/api"
import { SelectChangeEvent, Button, Box } from "@mui/material"
import { DownloadType } from "@/components/DownloadFromBlob/downloadFromBlob"
import { CitationsHighlightMode } from "@/components/CitationPDFViewer/CitationPDFViewer"
import { t } from "i18next"

interface Option {
    text: string
    key:
        | SearchApproaches
        | ModelVersions
        | AssistantTypes
        | ImproveSearchQueryModes
        | PreselectContextFilesModes
        | TokensUsageModes
}

interface PersonalSettings {
    assistantType: AssistantTypes
    selectedModel: ModelVersions
    selectedTokensUsageMode: TokensUsageModes
    improveSearchQueryMode: ImproveSearchQueryModes
    answerGenerationPromptTemplate: string
    cognitiveSearchQueryGenerationPromptTemplate: string
    temperatureCount: number
    docsSearchThreshold: number
    useSuggestFollowupQuestions: boolean
    preselectContextFilesMode: PreselectContextFilesModes
    searchApproach: SearchApproaches
    showWelcomeMessage: boolean
    pdfCitationDisplayMode: DownloadType
    citationsHighlightMode: CitationsHighlightMode
    modelProvider: string
    docsPreselectSearchThreshold: number
}

export const SearchApproachOptions: Option[] = [
    {
        text: "Auto (the type of search is automatically determined, suitable for most cases)",
        key: SearchApproaches.Auto,
    },
    {
        text: "Text (use when you need to grasp the context and intent behind the user's search query to provide more pertinent and thorough results)",
        key: SearchApproaches.Text,
    },
    {
        text: "Keyword (use when your query includes specific terms or phrases you want to locate in document)",
        key: SearchApproaches.Keyword,
    },
    {
        text: "Aggregator (use when you want to summarize/collect/compare/resume ALL information from the documents at the same time)",
        key: SearchApproaches.Aggregator,
    },
]

export const ModelOptions: Option[] = [
    { text: "Chat GPT-4o-mini", key: ModelVersions.GPT4_MINI },
    { text: "Chat GPT-4o (Default)", key: ModelVersions.GPT4 },
]

export const TokensUsageOptions: Option[] = [
    { text: "Standard (optimal balance between cost and quality)", key: TokensUsageModes.Standard },
    { text: "Increased (better results but more expensive)", key: TokensUsageModes.Increased },
    { text: "Highest (best results but most expensive and slower)", key: TokensUsageModes.Highest },
]

export const ImproveSearchQueryModesOptions: Option[] = [
    { text: "Always (your query will always be improved)", key: ImproveSearchQueryModes.Always },
    {
        text: "Manual (you can manually choose if you want your query to be improved)",
        key: ImproveSearchQueryModes.Manual,
    },
    { text: "Disabled (your query will not be improved)", key: ImproveSearchQueryModes.Disabled },
]

export const AutomaticallyPreselectContextFilesOptions: Option[] = [
    {
        text: "Enabled (documents will be preselected automatically if none are already chosen)",
        key: PreselectContextFilesModes.Enabled,
    },
    { text: "Disabled", key: PreselectContextFilesModes.Disabled },
]

export const AssistantTypeOptions: Option[] = [
    { text: "Knowledge Search Assistant", key: AssistantTypes.DOC_ASSISTANT },
    { text: "Chat Assistant", key: AssistantTypes.BASIC },
]

export type ProviderTypes = "azure" | "openai"

interface ProviderOption {
    text: string
    key: ProviderTypes
}

export const ProviderOptions: ProviderOption[] = [
    { text: "Azure", key: "azure" },
    { text: "OpenAI", key: "openai" },
]
export const ResetSettingsButton = () => {
    const dispatch = useDispatch()
    const [, setPersonalSettings] = useLocalStorage("personalSettings", {})

    const resetSettings = () => {
        const defaultSettings = {
            answerGenerationPromptTemplate: "",
            cognitiveSearchQueryGenerationPromptTemplate: "",
            useSuggestFollowupQuestions: false,
            preselectContextFilesMode: PreselectContextFilesModes.Disabled,
            searchApproach: SearchApproaches.Auto,
            temperatureCount: 0.3,
            docsPreselectSearchThreshold: 50,
            docsSearchThreshold: 30,
            selectedModel: ModelVersions.GPT4,
            assistantType: AssistantTypes.DOC_ASSISTANT,
            pdfCitationDisplayMode: DownloadType.Auto,
            citationsHighlightMode: CitationsHighlightMode.SIMPLE_PRIORITY,
            modelProvider: "azure",
            improveSearchQueryMode: ImproveSearchQueryModes.Manual,
        }

        Object.entries(defaultSettings).forEach(([key, value]) => {
            const actionCreator = {
                answerGenerationPromptTemplate: setAnswerGenerationPromptTemplate,
                cognitiveSearchQueryGenerationPromptTemplate: setCognitiveSearchQueryGenerationPromptTemplate,
                useSuggestFollowupQuestions: setUseSuggestFollowupQuestions,
                preselectContextFilesMode: setPreselectContexFilesMode,
                searchApproach: setSearchApproach,
                temperatureCount: setTemperatureCount,
                docsPreselectSearchThreshold: setDocsPreselectSearchThreshold,
                docsSearchThreshold: setDocsSearchThreshold,
                selectedModel: setModel,
                selectedTokensUsageMode: setTokensUsageMode,
                assistantType: setAssistantType,
                pdfCitationDisplayMode: setPdfCitationDisplayMode,
                citationsHighlightMode: setCitationsHighlightMode,
                modelProvider: setModelProvider,
                improveSearchQueryMode: setImproveSearchQueryMode,
            }[key]

            if (actionCreator) {
                dispatch(actionCreator(value))
            }
        })

        setPersonalSettings(defaultSettings)
    }

    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingTop: "20px",
            }}>
            <Button variant="outlined" color="primary" onClick={resetSettings} sx={{ marginRight: "10px" }}>
                {t("Reset all settings to their default values")}
            </Button>
        </Box>
    )
}
export const useSettings = () => {
    const dispatch = useDispatch()
    const settings = useSelector((state: any) => state.chat)
    const [personalSettings, setPersonalSettings] = useLocalStorage<PersonalSettings>("personalSettings", settings)

    const selectedAssistantTypeState = Object.values(AssistantTypeOptions).find(i => i.key === settings.assistantType)

    const selectedModelState = Object.values(ModelOptions).find(i => i.key === settings.model)
    const selectedTokensUsageModeState = Object.values(TokensUsageOptions).find(
        i => i.key === settings.selectedTokensUsageMode
    )

    const selectedImproveSearchQueryModeState = Object.values(ImproveSearchQueryModesOptions).find(
        i => i.key === settings.improveSearchQueryMode
    )

    const selectedSearchApproachState = Object.values(SearchApproachOptions).find(
        i => i.key === settings.searchApproach
    )

    const selectedPreselectContextFilesModeState = Object.values(AutomaticallyPreselectContextFilesOptions).find(
        i => i.key === settings.preselectContextFilesMode
    )

    const selectedProviderState = Object.values(ProviderOptions).find(i => i.key === settings.modelProvider)

    const selectedDocsPreselectSearchThreshold = settings.docsPreselectSearchThreshold

    const onAnswerGenerationPromptTemplateChange = (event: ChangeEvent<HTMLInputElement>) => {
        dispatch(setAnswerGenerationPromptTemplate(event.target.value || ""))
        setPersonalSettings({ ...personalSettings, answerGenerationPromptTemplate: event.target.value })
    }

    const onCognitiveSearchQueryGenerationPromptTemplateChange = (event: ChangeEvent<HTMLInputElement>) => {
        dispatch(setCognitiveSearchQueryGenerationPromptTemplate(event.target.value || ""))
        setPersonalSettings({ ...personalSettings, cognitiveSearchQueryGenerationPromptTemplate: event.target.value })
    }

    const onTemperatureCountChange = (event: ChangeEvent<HTMLInputElement>) => {
        const newValue = parseFloat(event.target.value)

        if (newValue >= 0 && newValue <= 1) {
            dispatch(setTemperatureCount(newValue))
            setPersonalSettings({ ...personalSettings, temperatureCount: newValue })
        }
    }

    const onDocsPreselectSearchThresholdChange = (
        event: ChangeEvent<HTMLInputElement> | number,
        newValue?: number | number[]
    ) => {
        let updatedValue: number
        if (typeof event === "number") {
            updatedValue = event
        } else if (newValue !== undefined) {
            updatedValue = newValue as number
        } else {
            updatedValue = parseInt((event.target as HTMLInputElement).value)
        }

        if (updatedValue >= 0 && updatedValue <= 100) {
            dispatch(setDocsPreselectSearchThreshold(updatedValue))
            setPersonalSettings({ ...personalSettings, docsPreselectSearchThreshold: updatedValue })
        }
    }

    const onDocsSearchThresholdChange = (
        event: ChangeEvent<HTMLInputElement> | number,
        newValue?: number | number[]
    ) => {
        let updatedValue: number
        if (typeof event === "number") {
            updatedValue = event
        } else if (newValue !== undefined) {
            updatedValue = newValue as number
        } else {
            updatedValue = parseInt((event.target as HTMLInputElement).value)
        }

        if (updatedValue >= 0 && updatedValue <= 100) {
            dispatch(setDocsSearchThreshold(updatedValue))
            setPersonalSettings({ ...personalSettings, docsSearchThreshold: updatedValue })
        }
    }

    const onUseSuggestFollowupQuestionsChange = (
        _ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
        checked?: boolean
    ) => {
        dispatch(setUseSuggestFollowupQuestions(!!checked))
        setPersonalSettings({ ...personalSettings, useSuggestFollowupQuestions: !!checked })
    }

    const onPreselectContextFilesModeChange = (event: SelectChangeEvent) => {
        const selectedMode = JSON.parse(event.target.value)
        dispatch(setPreselectContexFilesMode(selectedMode.key))
        setPersonalSettings({ ...personalSettings, preselectContextFilesMode: selectedMode?.key })
    }

    const onAssistantTypeChange = (event: SelectChangeEvent) => {
        const assistantType = JSON.parse(event.target.value)
        dispatch(setAssistantType(assistantType.key))
        setPersonalSettings({ ...personalSettings, assistantType: assistantType?.key })
    }

    const onModelChange = (event: SelectChangeEvent) => {
        const selectedModel = JSON.parse(event.target.value)
        dispatch(setModel(selectedModel.key))
        setPersonalSettings({ ...personalSettings, selectedModel: selectedModel?.key })
    }

    const onTokensUsageModeChange = (event: SelectChangeEvent) => {
        const selectedTokensUsageMode = JSON.parse(event.target.value)
        dispatch(setTokensUsageMode(selectedTokensUsageMode.key))

        setPersonalSettings({ ...personalSettings, selectedTokensUsageMode: selectedTokensUsageMode?.key })
    }

    const onImproveSearchQueryModeChange = (event: SelectChangeEvent) => {
        const selectedMode = JSON.parse(event.target.value)
        dispatch(setImproveSearchQueryMode(selectedMode.key))

        setPersonalSettings({ ...personalSettings, improveSearchQueryMode: selectedMode?.key })
    }

    const onSearchApproachChange = (event: SelectChangeEvent) => {
        const selectedApproach = JSON.parse(event.target.value)
        dispatch(setSearchApproach(selectedApproach.key))
        setPersonalSettings({ ...personalSettings, searchApproach: selectedApproach?.key })
    }
    const onShowWelcomeMessageChange = (value: boolean) => {
        setPersonalSettings({ ...personalSettings, showWelcomeMessage: value })
        dispatch(setShowWelcomeMessage(value))
    }

    const pdfCitationDisplayMode = useSelector((state: any) => state.chat.pdfCitationDisplayMode)

    const onPdfCitationDisplayModeChange = (event: SelectChangeEvent<DownloadType>) => {
        const newMode = event.target.value as DownloadType
        dispatch(setPdfCitationDisplayMode(newMode))
        setPersonalSettings({ ...personalSettings, pdfCitationDisplayMode: newMode })
    }

    const onCitationsHighlightModeChange = (event: SelectChangeEvent<CitationsHighlightMode>) => {
        dispatch(setCitationsHighlightMode(event.target.value as CitationsHighlightMode))
        setPersonalSettings({
            ...personalSettings,
            citationsHighlightMode: event.target.value as CitationsHighlightMode,
        })
    }
    const onModelProviderChange = (event: SelectChangeEvent) => {
        const selectedProvider = event.target.value
        dispatch(setModelProvider(selectedProvider))
        setPersonalSettings({ ...personalSettings, modelProvider: selectedProvider })
    }

    return {
        selectedModelState,
        selectedTokensUsageModeState,
        selectedAssistantTypeState,
        selectedSearchApproachState,
        selectedImproveSearchQueryModeState,
        selectedPreselectContextFilesModeState,
        selectedDocsPreselectSearchThreshold,
        onAnswerGenerationPromptTemplateChange,
        onCognitiveSearchQueryGenerationPromptTemplateChange,
        onTemperatureCountChange,
        onUseSuggestFollowupQuestionsChange,
        onAssistantTypeChange,
        onModelChange,
        onTokensUsageModeChange,
        onSearchApproachChange,
        onShowWelcomeMessageChange,
        pdfCitationDisplayMode,
        onPdfCitationDisplayModeChange,
        onCitationsHighlightModeChange,
        onDocsSearchThresholdChange,
        onModelProviderChange,
        selectedProviderState,
        onImproveSearchQueryModeChange,
        onPreselectContextFilesModeChange,
        onDocsPreselectSearchThresholdChange,
        ...settings,
    }
}
