import React, { useEffect, useState } from "react"
import { useModal } from "@/hooks/useModal"
import { useSettings } from "@/components/Settings/useSettings"
import { useSubscriptions } from "@/pages/subscriptions/useSubscriptions"
import { msalInstance } from "@/index"

const SubscriptionHandler = () => {
    const account = msalInstance.getActiveAccount()

    if (account) {
        const accessToken = account.idToken

        const isTokenValid = account.idTokenClaims?.exp ? account.idTokenClaims?.exp * 1000 > Date.now() : false

        if (accessToken && isTokenValid) {
            useSubscriptions(true)
        }
    }

    return null
}

export const LoginSuccessListener: React.FC = () => {
    const { welcomeMessage } = useModal()
    const { showWelcomeMessage } = useSettings()
    const [loginSuccess, setLoginSuccess] = useState(false)
    const [delayedExecution, setDelayedExecution] = useState(false)

    useEffect(() => {
        const handleLoginSuccess = () => {
            if (showWelcomeMessage) {
                welcomeMessage("Welcome!", "Welcome to aidocr! 🎉")
            }
            setLoginSuccess(true)
        }

        window.addEventListener("login-success", handleLoginSuccess)

        return () => {
            window.removeEventListener("login-success", handleLoginSuccess)
        }
    }, [welcomeMessage, showWelcomeMessage])

    // Effect to trigger delayed execution of useSubscriptions after login.
    useEffect(() => {
        if (loginSuccess) {
            const timer = setTimeout(() => {
                setDelayedExecution(true)
            }, 2000)

            return () => clearTimeout(timer)
        }
    }, [loginSuccess])

    const currentPath = window.location.href.split("#")[1] || "/"
    if (currentPath === "/terms-of-service" || currentPath === "/privacy-policy") {
        return null
    }

    return (
        <>
            {/* Render SubscriptionHandler immediately if not logged in. */}
            {!loginSuccess && <SubscriptionHandler />}
            {/* Render SubscriptionHandler after a delay when logged in. */}
            {delayedExecution && <SubscriptionHandler />}
        </>
    )
}
