// google-translate.ts
import i18n from "./../../i18n"

export const googleTranslateConfig: GoogleTranslateConfig = {
    /* Original language */
    lang: "en",

    /* The language we translate into on the first visit */
    // langFirstVisit: 'en',

    /* If the script does not work correctly, uncomment and specify the main domain in the domain property */
    // domain: "Get-Web.Site"
    domain: "aidocr.com",
}

interface GoogleTranslateConfig {
    lang: string
    langFirstVisit?: string
    domain?: string
    testWord?: string
}

interface CookieOptions {
    path?: string
    domain?: string

    [key: string]: any
}

// Helper function to get cookie
// function getCookie(name: string): string | undefined {
//     const value = `; ${document.cookie}`
//     const parts = value.split(`; ${name}=`)
//     if (parts.length === 2) {
//         return parts.pop()?.split(";").shift()
//     }
// }

// Helper function to set cookie
function setCookie(name: string, value: string, options: CookieOptions = {}): void {
    options = {
        path: "/",
        ...options,
    }
    // console.log("setCookie", name, value, options)

    let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value)

    for (const optionKey in options) {
        updatedCookie += "; " + optionKey
        const optionValue = options[optionKey]
        if (optionValue !== true) {
            updatedCookie += "=" + optionValue
        }
    }
    // console.log("updatedCookie", updatedCookie)

    document.cookie = updatedCookie
}

// Helper function to get item from localStorage
function getLocalStorage(key: string): string | null {
    const res = localStorage.getItem(key)
    // console.log("getLocalStorage", key, res)
    if (res === null) return null
    setLocalStorage("googtrans", res)
    return res
}

export function TranslateCookieHandler0(value: string | null, domain?: string): void {
    // show current cookie for googtrans
    // console.log("1 googtrans cookie:", document.cookie)
    setCookie("googtrans", value || "")
    if (domain !== undefined) {
        setCookie("googtrans", value || "", { domain: domain })
        setCookie("googtrans", value || "", { domain: "." + domain })
    } else {
        console.log("domain is undefined")
    }
    // set cookie for current domain
    const currentDomain = window.location.hostname
    // console.log("currentDomain:", currentDomain)
    setCookie("googtrans", value || "", { domain: currentDomain })

    // console.log("2 googtrans cookie:", document.cookie)
}

// Helper function to set item in localStorage
function setLocalStorage(key: string, value: string): void {
    if (value !== null && value !== "") {
        // console.log(`1 setLocalStorage key:${key} value:${value}`)
        localStorage.setItem(key, value)
        TranslateCookieHandler0(value, googleTranslateConfig.domain)
    }
}

// Main initialization function
export function TranslateInit(config: GoogleTranslateConfig) {
    if (config.langFirstVisit && !getLocalStorage("googtrans")) {
        TranslateCookieHandler(`/auto/${config.langFirstVisit}`)
    }

    const code = TranslateGetCode(config)
    // console.log("[GT] Current language code:", code)
    // if %2Fauto%2Ffr -> fr
    const i18n_code = code.replace(/%2Fauto%2F/g, "")
    try {
        i18n.changeLanguage(i18n_code)
    } catch (e) {
        console.error(e)
        setTimeout(() => {
            i18n.changeLanguage(i18n_code)
        }, 1000)
    }

    TranslateHtmlHandler(code)

    if (code === config.lang) {
        // console.log("Original language")

        setLocalStorage("googtrans", "")
    }

    if (config.testWord) TranslateMutationObserver(config.testWord, code === config.lang)

    // Initialize Google Translate Element
    new window.google.translate.TranslateElement(
        {
            pageLanguage: config.lang,
            includedLanguages: "en,de,fr,it,es,sv,ja",
            layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE,
        },
        "google_translate_element"
    )
}

// Get the current translation code
export function TranslateGetCode(config: GoogleTranslateConfig): string {
    let lang = getLocalStorage("googtrans")
    lang = lang !== undefined && lang !== "null" ? lang : config.lang
    if (lang === null) {
        lang = config.lang
        // console.log("TranslateGetCode", lang)
    }
    return lang.match(/(?!^\/)[^/]*$/gm)?.[0] || config.lang
}

// Handle translation cookies
export function TranslateCookieHandler(value: string | null, domain?: string): void {
    if (value !== null) {
        setLocalStorage("googtrans", value)
    } else {
        console.log("TranslateCookieHandler is null", value, domain)
    }
}

// Handle HTML updates for translation
export function TranslateHtmlHandler(code: string): void {
    const activeElement = document.querySelector(`[data-google-lang="${code}"]`) as HTMLElement
    if (activeElement) {
        // console.log("Active element:", activeElement)
        activeElement.classList.add("language__img_active")
    }
}

// Event handler for translation
// export function TranslateEventHandler(event: string, selector: string, handler: (el: Element) => void): void {
//     document.addEventListener(event, function (e: Event) {
//         const el = (e.target as Element).closest(selector)
//         if (el) handler(el)
//     })
// }

// Mutation observer for translation completion
function TranslateMutationObserver(word: string, isOrigin: boolean): void {
    // console.log("TranslateMutationObserver", word, isOrigin)
    if (isOrigin) {
        document.dispatchEvent(new CustomEvent("FinishTranslate"))
    } else {
        const div = document.createElement("div")
        div.id = "googleTranslateTestWord"
        div.innerHTML = word
        div.style.display = "none"
        document.body.prepend(div)

        const observer = new MutationObserver(() => {
            document.dispatchEvent(new CustomEvent("FinishTranslate"))
            observer.disconnect()
        })

        observer.observe(div, {
            childList: false,
            subtree: true,
            characterDataOldValue: true,
        })
    }
}

// Function to load Google Translate script
export function loadGoogleTranslate(callback: () => void): void {
    const existingScript = document.getElementById("google-translate-script")
    // console.log("Existing script:", existingScript)
    if (!existingScript) {
        // console.log("Loading Google Translate script")
        const script = document.createElement("script")
        script.src = "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
        script.id = "google-translate-script"
        script.async = true
        script.onload = () => callback()
        document.body.appendChild(script)
    } else if (callback) {
        callback()
    }
}

// Initialization function for Google Translate
;(window as any).googleTranslateElementInit = function () {
    TranslateInit(googleTranslateConfig)
}
