import { AskResponse } from "@/api"
import React, { useEffect, useState } from "react"
import axios from "axios"
import { Box, Skeleton, Tooltip } from "@mui/material"

export const ImageWithAuth = ({
    imageUrl,
    answer,
    index,
    handleImageClick,
    maxImageHeight,
}: {
    imageUrl: string
    answer?: AskResponse
    index: number
    handleImageClick: (src: string, thumbnailSrc: string) => void
    maxImageHeight: number
}) => {
    const [imgSrc, setImgSrc] = useState("")
    const [loading, setLoading] = useState(true)
    const [imageDimensions, setImageDimensions] = useState({ width: 0, height: 0 })

    const blobName = imageUrl?.split("/").pop()?.split("?")[0] || ""
    let description = ""

    if (answer) {
        const foundFigure = answer.cited_figures.find((fig: { blob_name: string }) => fig.blob_name === blobName)

        if (foundFigure) {
            description = foundFigure.figure_description
        }
    }

    useEffect(() => {
        const fetchImage = async () => {
            setLoading(true)
            try {
                const response = await axios.get(imageUrl, {
                    responseType: "blob",
                })

                const blob = response.data
                const objectURL = URL.createObjectURL(blob)

                // Preload the image to get dimensions
                const img = new Image()
                img.onload = () => {
                    const width = img.naturalWidth
                    const height = img.naturalHeight
                    setImageDimensions({ width, height })
                    setImgSrc(objectURL)
                }
                img.src = objectURL

                return () => URL.revokeObjectURL(objectURL)
            } catch (error) {
                console.error("Error fetching the image:", error)
            } finally {
                setLoading(false)
            }
        }

        fetchImage()
    }, [imageUrl])

    const calculatedHeight = Math.min(imageDimensions.height * 0.5, maxImageHeight)
    const fullImageUrl = imageUrl.replace("&thumbnail=true", "")
    return (
        <Box pl={1} key={index}>
            <div className="relative w-48 h-48">
                {loading ? (
                    <Skeleton
                        variant="rounded"
                        width={maxImageHeight}
                        height={maxImageHeight}
                        animation="wave"
                        sx={{ bgcolor: "#F1F1F1" }}
                    />
                ) : (
                    <Tooltip title={description} placement="left" enterDelay={500}>
                        <img
                            src={imgSrc}
                            alt=""
                            className="object-contain transition-opacity duration-300 opacity-100"
                            style={{
                                height: `${calculatedHeight}px`,
                                width: "auto",
                                display: imgSrc ? "block" : "none",
                                cursor: "pointer",
                            }}
                            onClick={() => handleImageClick(fullImageUrl, imgSrc)}
                        />
                    </Tooltip>
                )}
            </div>
        </Box>
    )
}
