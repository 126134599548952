import { Avatar, Box, Grid, IconButton, Stack, TextareaAutosize, Tooltip, Typography } from "@mui/material"

import { Lamp, TrashGrey } from "@/assets"
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { AskResponse } from "@/api"
import { stringAvatar, stringToColor } from "@/components/Header/Header"
import { useMsal } from "@azure/msal-react"
import { styles } from "@/components/Answer/styles"
import { useReadLocalStorage } from "usehooks-ts"
import EditIcon from "@mui/icons-material/Edit"
import CheckIcon from "@mui/icons-material/Check"
import CloseIcon from "@mui/icons-material/Close"
import RefreshIcon from "@mui/icons-material/Refresh"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"

interface Props {
    message: string
    onSupportingContentClicked?: () => void
    onThoughtProcessClicked?: () => void
    answer?: AskResponse
    onRemoveQA: () => void
    onEditConfirm: (message: string) => void
    onResend: () => void
    isLastMessage: boolean
    isHovering: boolean
}

export const UserChatMessage = ({
    message,
    onSupportingContentClicked,
    onThoughtProcessClicked,
    answer,
    onRemoveQA,
    onEditConfirm,
    onResend,
    isLastMessage,
    isHovering,
}: Props) => {
    const answers = useSelector((state: any) => state.chat.answers)
    const isDisabledSupportingContentTab: boolean = !answer?.data_points?.length
    const { accounts } = useMsal()
    let givenName = null
    let familyName = null
    const showTokens = useReadLocalStorage("showTokens")
    const [isEditMode, setIsEditMode] = useState(false)
    const [editedMessage, setEditedMessage] = useState(message)
    const tokenClaims = accounts[0]?.idTokenClaims
    const isLoading = useSelector((state: any) => state.chat.isLoading)
    if (tokenClaims != null) {
        givenName = tokenClaims.given_name ? (tokenClaims.given_name as string).toUpperCase() : null
        familyName = tokenClaims.family_name ? (tokenClaims.family_name as string).toUpperCase() : null
    }

    useEffect(() => {
        setEditedMessage(message)
    }, [message])

    const onCopy = () => {
        const answerText = answer?.answer

        if (!answerText) {
            return
        }

        const answerWithoutCitations = answerText.replace(/(\[.*?\])/g, "")
        const answerWithoutFollowUpQuestionsAndCitations = answerWithoutCitations.replace(/(<<.*?>>)/g, "")

        navigator.clipboard.writeText(answerWithoutFollowUpQuestionsAndCitations.trim())
    }

    return (
        <Grid
            className="notranslate"
            container
            sx={{
                padding: "20px 20px 10px 0",
                position: "relative",
                background: "#fff",
                alignItems: "flex-start",
                pr: "20px",
                flexWrap: "nowrap",
                borderTopLeftRadius: "4px",
                borderTopRightRadius: "4px",
            }}>
            <Grid
                item
                sx={{
                    margin: "0 20px",
                    marginTop: "10px",
                }}
                xs="auto">
                <Avatar
                    {...stringAvatar(`${givenName} ${familyName}`)}
                    sx={{
                        width: "5vw",
                        minWidth: "24px",
                        maxWidth: "34px",
                        height: "5vw",
                        minHeight: "24px",
                        maxHeight: "34px",
                        marginTop: "-5px",
                        marginBottom: "5px",
                        fontSize: "17px",
                        bgcolor: stringToColor(`${givenName} ${familyName}`),
                    }}
                />
            </Grid>
            <Grid item xs={11} sx={{ ...styles.answerText }}>
                {isEditMode ? (
                    <>
                        <TextareaAutosize
                            value={editedMessage}
                            onChange={e => setEditedMessage(e.target.value)}
                            minRows={1}
                            maxRows={10}
                            style={{
                                width: "105%",
                                border: "1px solid #ccc",
                                borderRadius: "4px",
                                padding: "10px",
                                marginBottom: "10px",
                                fontSize: "16px",
                                fontFamily: "Segoe UI",
                            }}
                        />
                        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                            <IconButton onClick={() => onEditConfirm(editedMessage)}>
                                <CheckIcon />
                            </IconButton>
                            <IconButton
                                onClick={() => {
                                    setIsEditMode(false)
                                    setEditedMessage(message)
                                }}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    </>
                ) : (
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography>{message}</Typography>
                    </Box>
                )}
            </Grid>
            {!!answers.length && (
                <>
                    <Grid item xs="auto" sx={{ marginRight: "0px", marginTop: "-3px" }}>
                        <Stack direction="row" spacing={0.4} justifyContent="flex-end">
                            <Tooltip title="Edit">
                                <IconButton
                                    onClick={() => setIsEditMode(true)}
                                    style={{
                                        visibility:
                                            isLastMessage && !isEditMode && isHovering && !isLoading
                                                ? "visible"
                                                : "hidden",
                                    }}>
                                    <EditIcon />
                                </IconButton>
                            </Tooltip>
                            {isLastMessage && (
                                <Tooltip title="Copy">
                                    <IconButton
                                        onClick={onCopy}
                                        style={{
                                            visibility: isHovering && !isLoading ? "visible" : "hidden",
                                        }}>
                                        <ContentCopyIcon style={{ fontSize: 22 }} />
                                    </IconButton>
                                </Tooltip>
                            )}
                            <Tooltip title="Regenerate">
                                <IconButton
                                    onClick={onResend}
                                    style={{
                                        visibility: isLastMessage && isHovering && !isLoading ? "visible" : "hidden",
                                    }}>
                                    <RefreshIcon />
                                </IconButton>
                            </Tooltip>
                            {!isLastMessage && (
                                <Tooltip title="Copy">
                                    <IconButton
                                        onClick={onCopy}
                                        style={{
                                            visibility: isHovering && !isLoading ? "visible" : "hidden",
                                        }}>
                                        <ContentCopyIcon style={{ fontSize: 22 }} />
                                    </IconButton>
                                </Tooltip>
                            )}
                            {!!showTokens && (
                                <IconButton
                                    style={{
                                        maxWidth: "42px",
                                    }}
                                    onClick={
                                        isDisabledSupportingContentTab
                                            ? onThoughtProcessClicked
                                            : onSupportingContentClicked
                                    }>
                                    <img
                                        style={{
                                            minWidth: "10px",
                                            maxWidth: "24px",
                                            visibility: isHovering ? "visible" : "hidden",
                                        }}
                                        src={Lamp}
                                        alt="Lamp"
                                    />
                                </IconButton>
                            )}
                            {isHovering && !isLoading ? (
                                <Tooltip title="Delete">
                                    <IconButton
                                        onClick={onRemoveQA}
                                        style={{
                                            maxWidth: "42px",
                                        }}>
                                        <img
                                            style={{ minWidth: "10px", maxWidth: "27px" }}
                                            src={TrashGrey}
                                            alt="Trash"
                                        />
                                    </IconButton>
                                </Tooltip>
                            ) : null}
                            {isLoading && (
                                <Tooltip title="Copy">
                                    <IconButton
                                        onClick={onCopy}
                                        style={{
                                            visibility: isHovering ? "visible" : "hidden",
                                        }}>
                                        <ContentCopyIcon style={{ fontSize: 22 }} />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </Stack>
                    </Grid>
                </>
            )}
        </Grid>
    )
}
