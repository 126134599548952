import { useDispatch, useSelector } from "react-redux"
import { setAnswerStream } from "@/slices/chatSlice"
import { useEffect, useRef } from "react"

export const useCharacterDispatch = (streamBuffer: string, isLoading: boolean, isChatGPT4: boolean) => {
    // console.log("00useCharacterDispatch is running", streamBuffer, new Date().toISOString())
    const dispatch = useDispatch()
    const currentAnswerRef = useRef("")
    const isDispatchingRef = useRef(false)
    const isStopRef = useRef(false)
    const cancelDispatchRef = useRef<NodeJS.Timeout | null>(null)
    const isStreamStopped = useSelector((state: any) => state.chat.isStreamStopped)

    useEffect(() => {
        if (!isLoading) {
            currentAnswerRef.current = ""
            isStopRef.current = true
            if (cancelDispatchRef.current) {
                clearTimeout(cancelDispatchRef.current)
            }
        } else {
            isStopRef.current = false
            isDispatchingRef.current = false
        }
    }, [isLoading])

    useEffect(() => {
        if (isLoading && streamBuffer.length === 0) {
            dispatch(setAnswerStream(""))
            currentAnswerRef.current = ""
            return
        }

        if (isLoading && streamBuffer.length > 0) {
            if (cancelDispatchRef.current) {
                clearTimeout(cancelDispatchRef.current)
            }

            isDispatchingRef.current = true
            let currentIndex = currentAnswerRef.current.length

            const getChar = (index: number) => {
                if (index < 0) {
                    return ""
                }
                return streamBuffer[index] ?? ""
            }

            const dispatchNextCharacter = () => {
                if (!isStopRef.current && currentIndex < streamBuffer.length && !isStreamStopped) {
                    const remainingChars = streamBuffer.length - currentIndex
                    const maxTimeout = isChatGPT4 ? 400 : 50
                    const acceleration = isChatGPT4 ? -0.01 : -0.025
                    const multipleCharsThreshold = isChatGPT4 ? 400 : 50
                    // TODO when switching to a faster chatgp4, adjust the threshold and maxTimeout to make it type faster
                    if (remainingChars > multipleCharsThreshold) {
                        const charsToSend = Math.min(
                            20,
                            Math.floor((remainingChars - multipleCharsThreshold) / multipleCharsThreshold / 2)
                        )
                        // console.log("charsToSend", charsToSend)
                        const nextChars = streamBuffer.slice(currentIndex, currentIndex + charsToSend)
                        currentAnswerRef.current += nextChars
                        dispatch(setAnswerStream(currentAnswerRef.current))
                        currentIndex += charsToSend
                    } else {
                        currentAnswerRef.current += getChar(currentIndex)
                        dispatch(setAnswerStream(currentAnswerRef.current))
                        currentIndex++
                    }

                    currentAnswerRef.current += getChar(currentIndex)
                    dispatch(setAnswerStream(currentAnswerRef.current))
                    currentIndex++

                    const timeout = Math.floor(maxTimeout * Math.exp(acceleration * (remainingChars - 1)))

                    // console.log("remainingChars", remainingChars, timeout)

                    cancelDispatchRef.current = setTimeout(dispatchNextCharacter, timeout)
                } else if (isStreamStopped) {
                    // console.log("Stream stopped, sending remaining characters")
                    // If stream is stopped and there are remaining characters, send all at once
                    const remainingChars = streamBuffer.slice(currentIndex)
                    currentAnswerRef.current += remainingChars
                    dispatch(setAnswerStream(currentAnswerRef.current))
                    currentIndex = streamBuffer.length
                } else {
                    isDispatchingRef.current = false
                }
            }

            dispatchNextCharacter()
        }

        return () => {
            if (cancelDispatchRef.current) {
                clearTimeout(cancelDispatchRef.current)
            }
        }
    }, [streamBuffer, isLoading, isChatGPT4, dispatch, isStreamStopped])

    return isDispatchingRef.current
}

export default useCharacterDispatch
