// DocumentEditableTextarea.tsx
import React, { useCallback, useState, useEffect } from "react"
import { Button, Grid, IconButton, InputBase, Tooltip } from "@mui/material"
import { GridToolbarContainer } from "@mui/x-data-grid"
import SearchIcon from "@mui/icons-material/Search"
import TuneIcon from "@mui/icons-material/Tune"
import DocumentSearchDrawer from "@/components/SmartFileSearch/DocumentSearchDrawer"
import { useFiles } from "@/hooks/useFiles"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import ClearIcon from "@mui/icons-material/Clear"
import { setChatLoading, setDocumentsSearchQuery } from "@/slices/chatSlice"
import { debounce } from "lodash"
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined"
import eventBus from "./eventBus"

interface EditableTextareaProps {
    initialValue?: string
    onChange?: (value: string) => void
}

export const DocumentCustomToolbar = () => {
    const { currentFolderPath } = useSelector((state: any) => state.chat)

    const handleBackButtonClick = () => {
        eventBus.emit("backButtonClick")
    }

    return (
        <GridToolbarContainer>
            <Grid display="flex" alignItems="center" width="100%">
                <Tooltip
                    PopperProps={{ disablePortal: true }}
                    placement={"top"}
                    title={"Go back to the previous folder"}
                    followCursor={false}>
                    <Button
                        variant="contained"
                        onClick={handleBackButtonClick}
                        disabled={!currentFolderPath}
                        sx={{
                            height: "33px",
                            minWidth: "44px",
                            width: "44px",
                            alignJustify: "center",
                            marginBottom: "5px",
                            marginLeft: "5px",
                            marginRight: "4px",
                        }}>
                        <DriveFolderUploadOutlinedIcon sx={{ fontSize: 28, color: "white" }} />
                    </Button>
                </Tooltip>

                <DocumentEditableTextarea />
            </Grid>
        </GridToolbarContainer>
    )
}

export const DocumentEditableTextarea: React.FC<EditableTextareaProps> = ({ initialValue = "" }) => {
    const [question, setQuestion] = useState(initialValue)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const { smartSearchFiles } = useFiles({ enabled: false })
    const isLoading = useSelector((state: any) => state.chat.isLoading)
    const { documentsSearchQuery } = useSelector((state: any) => state.chat)

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { fileFormats, fileSources, documentsDateRange, fileSizeRange, aiTags, manualTags, documentsSearchProfile } =
        useSelector((state: any) => state.chat)
    const handleOpenModal = () => {
        setIsModalOpen(true)
    }

    const handleCloseModal = () => {
        setIsModalOpen(false)
    }

    const debouncedSetSearchQuery = useCallback(
        debounce((value: string) => {
            dispatch(setDocumentsSearchQuery(value))
        }, 50),
        [dispatch]
    )

    // use effect to sync the question with the search query
    useEffect(() => {
        if (documentsSearchQuery !== question) {
            setQuestion(documentsSearchQuery)
        }
    }, [documentsSearchQuery])

    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const value = event.target.value
        setQuestion(value)
        debouncedSetSearchQuery(value)
    }
    const onSearchFiles = () => {
        // console.log("Searching for:", question)

        if (isLoading || !question.trim()) {
            return
        }
        dispatch(setChatLoading(true))
        smartSearchFiles.mutate({
            searchParameters: question,
            documentsSearchProfile: documentsSearchProfile,
            fileFormats: fileFormats,
            fileSources: fileSources,
            documentsDateRange: documentsDateRange,
            fileSizeRange: fileSizeRange,
        })

        navigate("/documents", { state: { enabled: false, isSearchFiles: true, searchParameters: question } })
    }
    return (
        <Grid
            container
            sx={{
                display: "flex",
                alignItems: "center",
                gap: "15px",
                minHeight: "45px",
                maxHeight: "100px",
                padding: "15px",
                paddingTop: "0px",
                backgroundColor: "#f0f4f7",
            }}
            wrap="nowrap">
            <Grid item xs style={{ paddingTop: "7px", paddingBottom: "7px", marginTop: "-4px", marginLeft: "5px" }}>
                <InputBase
                    value={question}
                    onChange={handleChange}
                    onKeyDown={e => {
                        if (e.key === "Enter" && !e.ctrlKey) {
                            onSearchFiles()
                            e.preventDefault()
                        } else if (e.ctrlKey && e.key === "Enter") {
                            const newValue = question + "\n"
                            setQuestion(newValue)
                            e.preventDefault()
                        }
                    }}
                    minRows={1}
                    maxRows={3}
                    placeholder="Search your documents by asking a question..."
                    multiline
                    margin="none"
                    size="small"
                    sx={{
                        width: "100%",
                        minHeight: "10px",
                        border: "1px solid",
                        borderColor: "grey.400",
                        borderRadius: "4px",
                        padding: "5px",
                        paddingLeft: "15px",
                        fontSize: "15px",
                        fontFamily: "Segoe UI",
                        outline: "none",
                        transition: "border-color 0.3s, box-shadow 0.3s",
                        backgroundColor: "background.paper",
                        "&:hover": {
                            borderColor: "grey.500",
                        },
                        "&.Mui-focused": {
                            borderColor: "primary.main",
                            boxShadow: theme => `0 0 0 2px ${theme.palette.primary.main}33`,
                        },
                        "&:hover:not(.Mui-focused)": {
                            boxShadow: theme => `0 0 0 2px ${theme.palette.grey[300]}`,
                        },
                    }}
                    endAdornment={
                        question && (
                            <IconButton
                                size="small"
                                onClick={() => {
                                    setQuestion("")
                                    debouncedSetSearchQuery("")
                                }}
                                sx={{ marginRight: "5px" }}>
                                <ClearIcon style={{ fontSize: "14px" }} />
                            </IconButton>
                        )
                    }
                />
            </Grid>
            <Grid
                item
                sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    paddingBottom: "10px",
                    height: "40px",
                    paddingTop: "0px",
                    marginTop: "-4px",
                    marginRight: "5px",
                }}>
                <Button
                    variant="contained"
                    onClick={onSearchFiles}
                    disabled={isLoading || !question.trim()}
                    sx={{ height: "31px", minWidth: { xs: "120px", sm: "150px" } }}>
                    <SearchIcon style={{ color: "white", marginRight: "8px" }} />
                    <span style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>
                        AI File Search
                    </span>
                </Button>
                <Button
                    variant="contained"
                    onClick={handleOpenModal}
                    sx={{ height: "31px", minWidth: { xs: "120px", sm: "150px" } }}>
                    <TuneIcon style={{ color: "white", marginRight: "8px" }} />
                    <span style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>Filters</span>
                </Button>
            </Grid>
            <DocumentSearchDrawer open={isModalOpen} handleClose={handleCloseModal} />
        </Grid>
    )
}
