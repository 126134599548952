// ChatWebPubSubProvider.tsx
import React, { createContext, useContext } from "react"
import { WebPubSubClient } from "@azure/web-pubsub-client"
import { HubNames, useAzureWebPubSub } from "@/components/NotificationCenter/useAzureWebPubSub"

interface ChatContextValue {
    chatPubSubClient: WebPubSubClient | null
    isChatClientConnected: boolean
}

const ChatContext = createContext<ChatContextValue>({
    chatPubSubClient: null,
    isChatClientConnected: false,
})

export const ChatWebPubSubProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
    const { pubSubClient, isClientConnected } = useAzureWebPubSub(HubNames.Chat)

    return (
        <ChatContext.Provider value={{ chatPubSubClient: pubSubClient, isChatClientConnected: isClientConnected }}>
            {children}
        </ChatContext.Provider>
    )
}

export const useChat = () => useContext(ChatContext)

export default ChatWebPubSubProvider
