import { configureStore } from "@reduxjs/toolkit"
import chatReducer from "./slices/chatSlice"
import workflowsReducer from "./slices/workflowsSlice"

export default configureStore({
    reducer: {
        chat: chatReducer,
        workflows: workflowsReducer,
    },
})
