import { BlobServiceClient, BlockBlobClient } from "@azure/storage-blob"
import axios from "axios"
import { openDB } from "idb"

export enum DownloadType {
    Page = "page",
    AllFile = "all_file",
    Auto = "auto",
}

const useAzureBlobDownloader = () => {
    const generateCacheKey = (fileName: string, pageNumber?: number, force_all?: boolean): string => {
        if (force_all) {
            return fileName
        }
        if (pageNumber !== undefined) {
            return `${fileName}-${pageNumber}`
        } else {
            return fileName
        }
    }
    const getFileDownloadDetails = async (fileId: string, pageNumber?: number) => {
        const params: Record<string, any> = {}

        if (pageNumber !== undefined) {
            params.page_number = pageNumber
            params.download_type = DownloadType.Page
        } else {
            params.download_type = DownloadType.AllFile
        }
        const response = await axios.get(`/files/download_with_sas/${fileId}`, { params })
        return response.data
    }

    const getBlockBlobClient = async (fileId: string, pageNumber?: number): Promise<BlockBlobClient> => {
        const downloadDetails = await getFileDownloadDetails(fileId, pageNumber)

        const blobServiceClient = new BlobServiceClient(
            `https://${downloadDetails.account_name}.blob.core.windows.net?${downloadDetails.sas_token}`
        )
        const containerClient = blobServiceClient.getContainerClient(downloadDetails.container_name)
        return containerClient.getBlockBlobClient(downloadDetails.blob_url)
    }

    const downloadChunk = async (blockBlobClient: BlockBlobClient, offset: number, count: number): Promise<Blob> => {
        const downloadBlockBlobResponse = await blockBlobClient.download(offset, count)
        return downloadBlockBlobResponse.blobBody!
    }

    const downloadFileInChunks = async (
        blockBlobClient: BlockBlobClient,
        totalSize: number,
        chunkSize: number
    ): Promise<Blob> => {
        const chunkCount = Math.ceil(totalSize / chunkSize)
        const chunkPromises: Promise<Blob>[] = []

        for (let i = 0; i < chunkCount; i++) {
            const start = i * chunkSize
            const count = Math.min(chunkSize, totalSize - start)
            chunkPromises.push(downloadChunk(blockBlobClient, start, count))
        }
        const chunks = await Promise.all(chunkPromises)

        return new Blob(chunks, { type: "application/octet-stream" })
    }

    // IndexedDB setup
    const dbName = "blobCache"
    const storeName = "blobs"

    const openDatabase = async () => {
        return openDB(dbName, 1, {
            upgrade(db) {
                db.createObjectStore(storeName)
            },
        })
    }

    const saveToCache = async (cacheKey: string, blob?: Blob) => {
        const db = await openDatabase()
        const data = { blob, timestamp: Date.now() }
        await db.put(storeName, data, cacheKey)
    }

    const removeOldCacheRecords = async () => {
        try {
            const db = await openDatabase()
            const currentTime = Date.now()
            const oneHour = 3600000 // 1 hour in milliseconds

            const keys = await db.getAllKeys(storeName)

            for (const key of keys) {
                const data = await db.get(storeName, key)

                if (data && currentTime - data.timestamp >= oneHour) {
                    await db.delete(storeName, key)
                }
            }
        } catch (error) {
            console.error("Error removing old cache records:", error)
        }
    }

    const getFromCache = async (cacheKey: string) => {
        const db = await openDatabase()

        // first try to get the full file
        let data = await db.get(storeName, cacheKey.split("-")[0])

        if (!data) {
            data = await db.get(storeName, cacheKey)
        }

        if (data) {
            const currentTime = Date.now()
            const oneHour = 3600000 // 1 hour in milliseconds
            if (currentTime - data.timestamp < oneHour) {
                return data.blob
            } else {
                await removeOldCacheRecords()
            }
        }
        return null
    }

    const initiateDownload = async (
        fileName: string,
        downloadType: DownloadType = DownloadType.Auto,
        pageNumber?: number
    ) => {
        try {
            let cacheKey = generateCacheKey(fileName, pageNumber, downloadType === DownloadType.AllFile)

            // Check cache first for Page and AllFile types
            const cachedBlob = await getFromCache(cacheKey)
            if (cachedBlob) {
                return cachedBlob
            }
            let blockBlobClient: BlockBlobClient
            let totalSize: number

            // Create blockBlobClient for Auto type or if not in cache
            if (downloadType === DownloadType.Auto) {
                blockBlobClient = await getBlockBlobClient(fileName)
                totalSize = (await blockBlobClient.getProperties()).contentLength!

                if (totalSize <= 20 * 1024 * 1024) {
                    cacheKey = generateCacheKey(fileName)
                } else {
                    blockBlobClient = await getBlockBlobClient(fileName, pageNumber)
                    totalSize = (await blockBlobClient.getProperties()).contentLength!
                }
            } else if (downloadType === DownloadType.Page) {
                blockBlobClient = await getBlockBlobClient(fileName, pageNumber)
                totalSize = (await blockBlobClient.getProperties()).contentLength!
            } else if (downloadType === DownloadType.AllFile) {
                blockBlobClient = await getBlockBlobClient(fileName)
                totalSize = (await blockBlobClient.getProperties()).contentLength!
            }

            const CHUNK_SIZE = 3 * 1024 * 1024 // 3MB

            // @ts-ignore
            const fileBlob = await downloadFileInChunks(blockBlobClient, totalSize, CHUNK_SIZE)

            // Save to cache
            await saveToCache(cacheKey, fileBlob)

            return fileBlob
        } catch (error) {
            console.error("Error downloading file:", error)
            return null
        }
    }

    return { initiateDownload }
}

export default useAzureBlobDownloader
