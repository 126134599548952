import { ReactPlugin } from "@microsoft/applicationinsights-react-js"
import { ApplicationInsights } from "@microsoft/applicationinsights-web"
import { createBrowserHistory } from "history"
import { Id, toast, ToastContent, ToastOptions } from "react-toastify"

// @ts-ignore
const browserHistory = createBrowserHistory({ basename: "" })
export const reactPlugin = new ReactPlugin()
export const appInsights = new ApplicationInsights({
    config: {
        connectionString: import.meta.env.VITE_APPINSIGHTS_CONNECTION_STRING,
        extensions: [reactPlugin],
        enableAutoRouteTracking: true,
        autoTrackPageVisitTime: true,
        extensionConfig: {
            [reactPlugin.identifier]: { history: browserHistory },
        },
    },
})
appInsights.loadAppInsights()

// Function to update accountId after MSAL is initialized
export const updateAppInsightsAccount = (msalInstance: { getActiveAccount: () => any }) => {
    const activeAccount = msalInstance.getActiveAccount()
    if (activeAccount) {
        // appInsights.config.accountId = activeAccount.localAccountId
        // appInsights.context.user.id = activeAccount.localAccountId
        appInsights.setAuthenticatedUserContext(activeAccount.localAccountId)

        // console.log("Updated App Insights account ID:", activeAccount.localAccountId)
    }
    appInsights.trackPageView()
    appInsights.trackEvent({ name: "App loaded" })
}

// Helper function to track telemetry based on severity
const trackTelemetry = (message: string, severity: "error" | "warning" | "information") => {
    switch (severity) {
        case "error":
            appInsights.trackException({ exception: new Error(message) })
            break
        case "warning":
            appInsights.trackTrace({ message, severityLevel: 2 })
            break
        case "information":
            appInsights.trackTrace({ message, severityLevel: 1 })
            break
    }
}

// Wrap console methods
const consoleMethodsToTrack: ("error" | "warn" | "info" | "log")[] = ["error", "warn", "info", "log"]

consoleMethodsToTrack.forEach(method => {
    const originalMethod = console[method]
    console[method] = function (...args: any[]) {
        originalMethod.apply(console, args)
        const message = args.map(arg => (typeof arg === "object" ? JSON.stringify(arg) : arg)).join(" ")

        switch (method) {
            case "error":
                trackTelemetry(message, "error")
                break
            case "warn":
                trackTelemetry(message, "warning")
                break
            case "info":
            case "log":
                trackTelemetry(message, "information")
                break
        }
    }
})

// Wrap toast methods
const toastMethodsToTrack: ("error" | "warn" | "info" | "success")[] = ["error", "warn", "info", "success"]
type ToastMethod = <TData = unknown>(content: ToastContent<TData>, options?: ToastOptions<TData>) => Id

toastMethodsToTrack.forEach(method => {
    const originalMethod = toast[method] as ToastMethod
    toast[method] = function <TData = unknown>(content: ToastContent<TData>, options?: ToastOptions<TData>) {
        const result = originalMethod(content, options)

        const message = typeof content === "string" ? content : JSON.stringify(content)

        switch (method) {
            case "error":
                trackTelemetry(message, "error")
                break
            case "warn":
                trackTelemetry(message, "warning")
                break
            case "info":
            case "success":
                trackTelemetry(message, "information")
                break
        }

        return result
    }
})
