import React, { createContext, PropsWithChildren, useState } from "react"

import { ModalProvider } from "./ModalProvider"
import { ModalContextProps, ModalsKeys } from "./Modals"

interface ModalContextState {
    modal: ModalsKeys
    setModal: (modal: ModalsKeys) => void
    props: ModalContextProps
    setProps: (props: ModalContextProps) => void
}

const contextDefaultValues: ModalContextState = {
    modal: ModalsKeys.None,
    setModal: () => {},
    setProps: () => {},
    props: {},
}

export const ModalContext = createContext<ModalContextState>(contextDefaultValues)

export const ModalContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const [modal, setModal] = useState(contextDefaultValues.modal)
    const [props, setProps] = useState(contextDefaultValues.props)

    return (
        <ModalContext.Provider
            value={{
                modal,
                setModal,
                props,
                setProps,
            }}>
            <ModalProvider>{children}</ModalProvider>
        </ModalContext.Provider>
    )
}
