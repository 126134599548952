import { SxProps, Theme } from "@mui/system"

export const styles: Record<string, SxProps<Theme>> = {
    answerContainer: {
        background: "rgb(249, 249, 249)",
        borderRadius: "4px",
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.14), 0px 0px 2px rgba(0, 0, 0, 0.12)",
        outline: "transparent solid 1px",
        width: "100%",
    },
    answerLogo: {
        fontSize: "28px",
    },
    answerText: {
        fontSize: "16px",
        fontWeight: "400",
        lineHeight: "22px",
        mt: "0px",
        pt: "2px",
        pb: "16px",
        whiteSpace: "pre-line",
        "& table": {
            borderCollapse: "collapse",
        },
        "& td, & th": {
            border: "1px solid",
            padding: "5px",
        },
        "& ul, & ol": {
            marginTop: "-0.5rem",
            marginBottom: "-0.5rem",
        },
        "& li": {
            marginBottom: "-0.5rem",
            marginTop: "-0.5rem",
        },
        "& h1, & h2, & h3, & h4, & h5, & h6": {
            marginTop: "1.3rem",
            marginBottom: "-0.2rem",
        },
        "& p": {
            marginTop: "0.5rem",
            marginBottom: "0.5rem",
        },
        "& blockquote": {
            marginTop: "0.5rem",
            marginBottom: "0.5rem",
        },
    },
    citationLearnMore: {
        marginRight: "5px",
        fontWeight: "400",
        lineHeight: "24px",
        color: "#4e7cbf",
    },
    citation: {
        fontWeight: "500",
        lineHeight: "24px",
        textAlign: "center",
        borderRadius: "4px",
        px: "8px",
        background: "#d1dbfa",
        color: "#123bb6",
        textDecoration: "none",
        cursor: "pointer",
        "&:hover": {
            textDecoration: "underline",
        },
    },
    followupQuestionLearnMore: {
        marginRight: "5px",
        fontWeight: "600",
        lineHeight: "24px",
    },
    followupQuestion: {
        lineHeight: "24px",
        textAlign: "center",
        borderRadius: "4px",
        px: "8px",
        color: "black",
        textDecoration: "none",
        cursor: "pointer",
    },
    supContainer: {
        textDecoration: "none",
        cursor: "pointer",
        "&:hover": {
            textDecoration: "underline",
        },
    },
    sup: {
        position: "relative",
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "10px",
        fontWeight: "600",
        verticalAlign: "top",
        top: "-1",
        mx: "2px",
        minWidth: "14px",
        height: "14px",
        borderRadius: "4px",
        background: "#d1dbfa",
        color: "#123bb6",
        textDecorationColor: "transparent",
        outline: "transparent solid 1px",
        cursor: "pointer",
    },
    retryButton: {
        width: "fit-content",
    },
    errorIcon: {
        width: "36px",
        height: "36px",
    },
    answerWrap: {
        display: "flex",
        "& svg": {
            width: "36px",
            height: "36px",
            marginRight: "20px",
        },
    },
    loadingdots: {
        "&::after": {
            content: "",
        },
    },
    message: {
        padding: "12px 0",
        borderRadius: "4px",
        display: "flex",
        alignItems: "flex-start",
        gap: "10px",
    },
}
