import { createSlice } from "@reduxjs/toolkit"
import { Workflow } from "@/api/workflows"

export const workflowsSlice = createSlice({
    name: "workflows",
    initialState: {
        workflowJSON: "",
        isWorkflowRunning: false,
        workflows: [] as Workflow[],
        workflowsPaginationModel: { pageSize: 10, page: 0 },
        isWorkflowJsonViewOpened: false,
        selectedWorkflow: {} as Workflow,
        workflowsSearchQuery: "",
    },
    reducers: {
        setWorkflowJSON: (state, action) => {
            state.workflowJSON = action.payload
        },
        setIsWorkflowRunning: (state, action) => {
            state.isWorkflowRunning = action.payload
        },
        setWorkflows: (state, action) => {
            state.workflows = action.payload
        },
        setWorkflowsPaginationModel: (state, action) => {
            state.workflowsPaginationModel = action.payload
        },
        setIsWorkflowJsonViewOpened: (state, action) => {
            state.isWorkflowJsonViewOpened = action.payload
        },
        setSelectedWorkflow: (state, action) => {
            state.selectedWorkflow = action.payload
        },
        setWorkflowsSearchQuery: (state, action) => {
            state.workflowsSearchQuery = action.payload
        },
    },
})

export const {
    setWorkflowJSON,
    setIsWorkflowRunning,
    setWorkflows,
    setWorkflowsPaginationModel,
    setIsWorkflowJsonViewOpened,
    setSelectedWorkflow,
    setWorkflowsSearchQuery,
} = workflowsSlice.actions

export default workflowsSlice.reducer
