import { msalInstance } from "@/index"
import { loginRequest } from "@/config/authConfig"

export const getValidAccessToken = async (): Promise<string | null> => {
    const account = msalInstance.getActiveAccount()

    if (account) {
        let accessToken = account.idToken

        const isTokenValid = account.idTokenClaims?.exp ? account.idTokenClaims?.exp * 1000 > Date.now() : false

        if (accessToken && isTokenValid) {
            return accessToken
        }

        const accessTokenResponse = await msalInstance.acquireTokenSilent({ ...loginRequest, account })

        if (accessTokenResponse) {
            // Should be accessTokenResponse.accessToken, but it's not working, so we use idToken
            accessToken = accessTokenResponse.idToken

            if (accessToken) {
                return accessToken
            }
        }
    }

    return null
}
