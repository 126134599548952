// FileSearchDrawer.tsx
import React from "react"
import { DatePicker, Slider } from "antd"
import dayjs, { Dayjs } from "dayjs"
import {
    Box,
    Button,
    Chip,
    Drawer,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Paper,
    Radio,
    RadioGroup,
    Select,
    SelectChangeEvent,
    Typography,
} from "@mui/material"
import { Clear } from "@mui/icons-material"
import { styled } from "@mui/system"
import { useDispatch, useSelector } from "react-redux"
import {
    setDocumentsDateRange,
    setDocumentsSearchProfile,
    setDocumentsSearchQuery,
    setFileFormats,
    setFileSizeRange,
    setFileSources,
} from "@/slices/chatSlice"
import { useFiles } from "@/hooks/useFiles"
import { useSettings } from "@/components/Settings/useSettings"
import { useNavigate } from "react-router-dom"

interface FileSearchDrawerProps {
    open: boolean
    handleClose: () => void
}

interface AvailableOptions {
    tags: string[]
    sources: string[]
    formats: string[]
}

const { RangePicker } = DatePicker

const StyledRangePicker = styled(RangePicker)`
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 4px;

    & .ant-picker-input input::placeholder {
        color: rgba(0, 0, 0, 0.6);
        opacity: 1;
    }

    &:hover {
        border-color: rgba(0, 0, 0, 0.87);
    }

    &:focus,
    &.ant-picker-focused {
        border-color: #1976d2;
        box-shadow: 0 0 0 2px rgba(25, 118, 210, 0.2);
    }
`

const settings = ["Everything", "Title", "Content", "Tags"]

const DocumentSearchDrawer: React.FC<FileSearchDrawerProps> = ({ open, handleClose }) => {
    const dispatch = useDispatch()
    const { documentsSearchQuery } = useSelector((state: any) => state.chat)
    const { docsSearchThreshold, onDocsSearchThresholdChange } = useSettings()
    const { fileFormats, fileSources, documentsDateRange, fileSizeRange, aiTags, manualTags, documentsSearchProfile } =
        useSelector((state: any) => state.chat)
    const { smartSearchFiles } = useFiles({ enabled: false })
    const navigate = useNavigate()

    const handleSearchClick = () => {
        // console.log("Searching for:", documentsSearchQuery)

        smartSearchFiles.mutate({
            searchParameters: documentsSearchQuery,
            documentsSearchProfile: documentsSearchProfile,
            fileFormats: fileFormats,
            fileSources: fileSources,
            documentsDateRange: [documentsDateRange[0]?.toDate() || null, documentsDateRange[1]?.toDate() || null],
            fileSizeRange: [fileSizeRange[0], fileSizeRange[1]],
        })

        handleClose()
        navigate("/documents", {
            state: { enabled: false, isSearchFiles: true, searchParameters: documentsSearchQuery },
        })
    }

    const availableOptions: AvailableOptions = {
        tags: ["tag1", "tag2", "tag3"],
        sources: ["web upload", "google_drive", "onedrive", "email", "icloud mail", "gmail", "outlook", "e-attachment"],
        formats: [
            "doc",
            "docx",
            "odt",
            "txt",
            "xls",
            "xlsx",
            "ods",
            "csv",
            "ppt",
            "pptx",
            "odp",
            "pdf",
            "jpg",
            "jpeg",
            "png",
            "gif",
            "bmp",
            "tiff",
            "tif",
            "webp",
            "html",
            "htm",
            "xhtml",
            "mhtml",
            "one",
        ],
    }

    const handleFileFormatsChange = (event: SelectChangeEvent<string[]>) => {
        dispatch(setFileFormats(event.target.value as string[]))
    }

    const handleFileSourcesChange = (event: SelectChangeEvent<string[]>) => {
        dispatch(setFileSources(event.target.value as string[]))
    }

    const onRangeChange = (dates: [Dayjs | null, Dayjs | null] | null) => {
        if (dates) {
            dispatch(setDocumentsDateRange(dates))
        } else {
            dispatch(setDocumentsDateRange([null, null]))
        }
    }

    const handleFileSizeRangeChange = (value: [number, number]) => {
        dispatch(setFileSizeRange(value))
    }

    const handleSearchProfileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(setDocumentsSearchProfile(event.target.value))
    }

    const rangePresets: Record<string, () => [Dayjs | null, Dayjs | null]> = {
        Today: () => [dayjs().startOf("d"), dayjs()],
        "Last 7 Days": () => [dayjs().subtract(7, "d"), dayjs()],
        "Last 14 Days": () => [dayjs().subtract(14, "d"), dayjs()],
        "Last Month": () => [dayjs().subtract(1, "M"), dayjs()],
        "Last 3 Months": () => [dayjs().subtract(3, "M"), dayjs()],
        "Last 6 Months": () => [dayjs().subtract(6, "M"), dayjs()],
        "Last Year": () => [dayjs().subtract(1, "y"), dayjs()],
    }

    return (
        <Drawer
            anchor="right"
            open={open}
            onClose={handleClose}
            sx={{
                zIndex: 200,
                "& .MuiDrawer-paper": {
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    backgroundColor: "#F1F1F1",
                },
            }}>
            <Paper
                elevation={1}
                component={"header"}
                sx={{
                    width: "450px",
                    padding: "13px 20px",
                    paddingLeft: "13px",
                    paddingBottom: "18.5px",
                    zIndex: 1,
                    borderRadius: "0px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                }}>
                <Typography
                    variant="h2"
                    component="div"
                    sx={{ marginTop: "10px", paddingLeft: "0px", backgroundColor: "#FBFBFB" }}>
                    File Search Settings
                </Typography>
                <IconButton
                    onClick={() => handleClose()}
                    type="button"
                    color="error"
                    sx={{
                        p: "10px",
                        position: "absolute",
                        top: "9px",
                        right: "10px",
                    }}>
                    <Clear />
                </IconButton>
            </Paper>
            <Box sx={{ width: 450, padding: "15px", paddingLeft: "20px", paddingRight: "25px", flexGrow: 1 }}>
                <Grid container spacing={"5px"} pt={1} sx={{ marginBottom: "20px" }}>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1" gutterBottom mb={1} mt={"5px"}>
                            AI search profile
                        </Typography>
                        <Grid container>
                            <Grid
                                item
                                xs={6}
                                sx={{
                                    marginBottom: "20px",
                                }}>
                                <RadioGroup value={documentsSearchProfile} onChange={handleSearchProfileChange}>
                                    {settings.slice(0, settings.length / 2).map((setting: string) => (
                                        <FormControlLabel
                                            key={setting}
                                            value={setting}
                                            control={<Radio />}
                                            label={setting}
                                        />
                                    ))}
                                </RadioGroup>
                            </Grid>
                            <Grid item xs={6}>
                                <RadioGroup value={documentsSearchProfile} onChange={handleSearchProfileChange}>
                                    {settings.slice(settings.length / 2).map((setting: string) => (
                                        <FormControlLabel
                                            key={setting}
                                            value={setting}
                                            control={<Radio />}
                                            label={setting}
                                        />
                                    ))}
                                </RadioGroup>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Box
                                sx={{
                                    borderTop: "1px solid #e0e0e0",
                                    marginBottom: "15px",
                                    width: "450px",
                                    marginLeft: "-20px",
                                }}
                            />
                            <Typography variant="subtitle1" gutterBottom mb={1}>
                                Smart Document Search Score Threshold
                            </Typography>
                            <Slider
                                value={100 - docsSearchThreshold}
                                // reverse={true}
                                onChange={event => onDocsSearchThresholdChange(100 - event)}
                                marks={{
                                    100: "1%",
                                    // 25: "25",
                                    // 50: "50",
                                    // 75: "75",
                                    1: "100%",
                                }}
                                //valueLabelDisplay="auto"
                                // valueLabelFormat={(value: number) => `${value}%`}
                                // tooltipVisible
                                // tipFormatter={value => `${value}`}
                                tooltip={{
                                    formatter: value => `Show the top ${value}% of search results`,
                                    // open: "auto",
                                }}
                                // step={5}
                                min={0}
                                max={100}
                                style={{ marginBottom: "40px" }}
                            />
                        </Grid>
                        <Box
                            sx={{
                                borderTop: "1px solid #e0e0e0",
                                marginBottom: "15px",
                                width: "450px",
                                marginLeft: "-20px",
                            }}
                        />
                        <Typography variant="subtitle1" gutterBottom mb={1} mt={"5px"}>
                            File Filters
                        </Typography>
                        <FormControl
                            fullWidth
                            sx={{
                                marginBottom: "20px",
                                backgroundColor: "white",
                            }}>
                            <InputLabel id="file-formats-label">File Formats</InputLabel>
                            <Select
                                multiple
                                fullWidth
                                label="File Formats"
                                labelId="file-formats-label"
                                // InputLabelProps={{
                                //     style: {
                                //         backgroundColor: "red",
                                //         color: "black",
                                //         fontColor: "black",
                                //         fontSize: "16px",
                                //     },
                                // }}
                                value={fileFormats}
                                onChange={handleFileFormatsChange}
                                renderValue={selected =>
                                    selected.length > 0 ? (
                                        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                                            {selected.map(value => (
                                                <Chip key={value} label={value} />
                                            ))}
                                        </Box>
                                    ) : (
                                        "All formats"
                                    )
                                }>
                                {availableOptions.formats.map(format => (
                                    <MenuItem key={format} value={format} className="notranslate">
                                        {format}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl fullWidth sx={{ marginBottom: "20px", backgroundColor: "white" }}>
                            <InputLabel id="file-sources-label">File Sources</InputLabel>
                            <Select
                                labelId="file-sources-label"
                                label="File Sources"
                                multiple
                                value={fileSources}
                                onChange={handleFileSourcesChange}
                                renderValue={selected =>
                                    selected.length > 0 ? (
                                        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                                            {selected.map(value => (
                                                <Chip key={value} label={value} />
                                            ))}
                                        </Box>
                                    ) : (
                                        "All sources"
                                    )
                                }>
                                {availableOptions.sources.map(source => (
                                    <MenuItem key={source} value={source} className="notranslate">
                                        {source}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                        <Box
                            sx={{
                                borderTop: "1px solid #e0e0e0",
                                marginBottom: "15px",
                                width: "450px",
                                marginLeft: "-20px",
                            }}
                        />
                        <Typography variant="subtitle1" gutterBottom mb={1}>
                            Date Range
                        </Typography>
                        <StyledRangePicker
                            value={documentsDateRange}
                            ranges={rangePresets}
                            onChange={onRangeChange}
                            style={{
                                marginBottom: "20px",
                                marginRight: "20px",
                                zIndex: 9999,
                                width: "100%",
                                height: "56px",
                            }}
                            placeholder={["From beginning", "Till Now"]}
                            allowEmpty={[true, true]}
                            size="large"
                            format="YYYY MMM DD"
                            sx={{ ".ant-picker-dropdown": { zIndex: 9999 } }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Box
                            sx={{
                                borderTop: "1px solid #e0e0e0",
                                marginBottom: "15px",
                                width: "450px",
                                marginLeft: "-20px",
                            }}
                        />
                        <Typography variant="subtitle1" gutterBottom mb={1}>
                            File Size Range
                        </Typography>
                        <Slider
                            range={{ draggableTrack: false }}
                            marks={{ 0: "0 MB", 100: "Infinity" }}
                            value={fileSizeRange}
                            onChange={(value: number | number[]) => {
                                if (Array.isArray(value)) {
                                    handleFileSizeRangeChange(value as [number, number])
                                } else {
                                    handleFileSizeRangeChange([value, value])
                                }
                            }}
                            style={{ marginBottom: "40px" }}
                            tooltip={{
                                formatter: (value: number | undefined) =>
                                    value === undefined ? "" : value === 100 ? "Infinity" : `${value} MB`,
                            }}
                        />
                    </Grid>
                </Grid>
            </Box>
            <Box
                sx={{
                    padding: "15px",
                    paddingLeft: "20px",
                    paddingRight: "25px",
                    display: "flex",
                    justifyContent: "flex-end",
                    paddingBottom: "20px",
                }}>
                <Button onClick={handleClose} sx={{ marginRight: 1 }}>
                    Cancel
                </Button>
                <Button
                    onClick={() => {
                        dispatch(setFileFormats([]))
                        dispatch(setFileSources([]))
                        dispatch(setFileSizeRange([0, 100]))
                        onRangeChange([null, null])
                        dispatch(setDocumentsSearchProfile("Everything"))
                        dispatch(setDocumentsSearchQuery(""))
                        onDocsSearchThresholdChange(30)
                    }}
                    sx={{ marginRight: 1 }}>
                    Reset
                </Button>
                <Button onClick={handleSearchClick} variant="contained">
                    Search
                </Button>
            </Box>
        </Drawer>
    )
}

export default DocumentSearchDrawer
