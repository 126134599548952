import React from "react"
import ReactDOM from "react-dom/client"
import "regenerator-runtime/runtime"
import { QueryClient, QueryClientProvider } from "react-query"
import { Provider } from "react-redux"

import "react-toastify/dist/ReactToastify.css"
import store from "@/store"
import { ReactQueryDevtools } from "react-query/devtools"
import { MsalProvider } from "@azure/msal-react"
import { msalConfig } from "@/config/authConfig"
import { AuthenticationResult, EventMessage, EventType, PublicClientApplication } from "@azure/msal-browser"
import { ErrorBoundary } from "@/components/ErrorBoundary"
import "./i18n"
import App from "@/App"
import { updateAppInsightsAccount } from "@/telemetry/appInsights"
import { initI18n } from "./i18n"

initI18n().catch(console.error)

export const msalInstance = new PublicClientApplication(msalConfig)

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            cacheTime: 0,
        },
    },
})

// Account selection logic is app dependent. Adjust as needed for different use cases.
const accounts = msalInstance.getAllAccounts()
if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0])
    updateAppInsightsAccount(msalInstance)
}

msalInstance.addEventCallback(async (event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        const payload = event.payload as AuthenticationResult
        const account = payload.account

        msalInstance.setActiveAccount(account)
        window.dispatchEvent(new CustomEvent("login-success"))
    } else if (event.eventType === EventType.LOGOUT_SUCCESS) {
        msalInstance.setActiveAccount(null)
    }
})

window.addEventListener("error", function (event) {
    const currentPath = window.location.href.split("#")[1] || "/"
    if (currentPath === "/terms-of-service" || currentPath === "/privacy-policy") {
        return null
    }

    if (event) {
        console.error("Global error caught by window event listener:", event, event.error)

        if (event.message?.includes("ResizeObserver")) {
            return
        }
    }

    const maxRetries = 10
    const retryTimeframe = 30 * 60 * 1000 // 30 minutes

    const now = new Date().getTime()
    const lastRetryTime = parseInt(sessionStorage.getItem("lastRetryTime") ?? "0", 10)
    const retryCount = parseInt(sessionStorage.getItem("retryCount") ?? "0", 10)

    // Check if the last retry was within the past 30 minutes
    if (now - lastRetryTime < retryTimeframe) {
        if (retryCount < maxRetries) {
            // If within 30 minutes, increment the retry count
            sessionStorage.setItem("retryCount", (retryCount + 1).toString())
            sessionStorage.setItem("lastRetryTime", now.toString())
            setTimeout(
                () => {
                    console.log("Reloading the page after error")
                    window.location.reload()
                },
                retryCount * retryCount * 1000
            )
        } else {
            // Handle the case where max retries have been reached
            console.log("Max retries reached within the timeframe. Not reloading.")
        }
    } else {
        // If outside the 30 minutes, reset the retry count and retry
        sessionStorage.setItem("retryCount", "1")
        sessionStorage.setItem("lastRetryTime", now.toString())
        window.location.reload()
    }
})

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <React.StrictMode>
        <MsalProvider instance={msalInstance}>
            <QueryClientProvider client={queryClient}>
                <Provider store={store}>
                    <ErrorBoundary>
                        <App />
                    </ErrorBoundary>
                </Provider>
                <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
        </MsalProvider>
    </React.StrictMode>
)
