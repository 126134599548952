// api.ts
import axios from "axios"

export interface FileSchemaResponse {
    id: string
    file_name: string
    metadata: Record<string, any>
    ai_tags: Record<string, any>
    manual_tags: Record<string, any>
    summary: string
    folder_id?: string
    uploaded_by_user_id?: string
    file_size?: number
    uploaded_time_utc?: string
    updated_time_utc?: string
    number_of_pages?: number
    score: string
    file_source?: string
    connector_name?: string
    is_folder?: boolean
    file_folder?: string
}

export async function getFiles(path?: string): Promise<Array<FileSchemaResponse>> {
    try {
        const response = await axios.get("/files", {
            params: {
                folder_name: path,
            },
        })
        return response.data
    } catch (error) {
        throw new Error("Could not get files")
    }
}

export async function getFileInfo(id: string, fileName: string | null): Promise<FileSchemaResponse> {
    try {
        let url = `/files/${id}`
        if (fileName) {
            url += `?file_name=${fileName}`
        }
        const response = await axios.get(url)
        return response.data
    } catch (error) {
        throw new Error("Could not get file info")
    }
}

export type FileUpdateSchema = {
    file_name: string
    id: string
    manual_tags: any
}

export async function updateFile(id: string, params: FileUpdateSchema): Promise<boolean> {
    try {
        const response = await axios.patch(`/files/${id}`, { ...params })

        if (response.status < 200 || response.status >= 300) {
            throw new Error("Could not update file")
        }

        return true
    } catch (error) {
        if (axios.isAxiosError(error) && error.response && error.response.status === 403) {
            throw new Error(error.response.data.detail || "Could not update file")
        }

        console.error("Error updating file:", error)
        throw new Error("Could not update file")
    }
}

export async function deleteFiles(ids: Array<string>) {
    try {
        const response = await axios.delete("/files/delete", { data: ids })

        if (response.status < 200 || response.status >= 300) {
            throw new Error("File deletion failed")
        }

        return {
            status: response.status,
            data: response.data,
        }
    } catch (error) {
        throw new Error("File deletion failed")
    }
}

export async function downloadFile(fileId: string, onDownloadProgress?: (progressEvent: any) => void) {
    try {
        const response = await axios.get(`/files/download/${fileId}`, {
            responseType: "blob",
            onDownloadProgress: onDownloadProgress,
        })

        if (!response.data) {
            throw new Error("File download failed")
        }

        return response.data
    } catch (error) {
        throw new Error("File download failed")
    }
}

export type Question = {
    _id: any
    number_of_clicks: number
    text: string
    timestamp: any
}

export async function getTopQuestions(): Promise<Array<Question>> {
    try {
        const response = await axios.get("chat/top_questions")

        if (!response.data) {
            throw new Error("Could not get top questions")
        }

        return response.data
    } catch (error) {
        throw new Error("Could not get top questions")
    }
}

interface LastQuestion {
    _id: string
    text: string
    timestamp: Date
    number_of_clicks: number
    created_by_user_id: string
}

export const getLastQuestions = async (): Promise<Array<LastQuestion>> => {
    const { data } = await axios.get("chat/last_questions", {
        method: "GET",
    })

    if (!data) {
        throw new Error("Could not get last questions")
    }

    return data
}

export type ShowcaseQuestion = {
    question: string
    title: string
}

export type Showcase = {
    id: string
    questions: Array<ShowcaseQuestion>
    timestamp: string
}

export const getShowcaseQuestion = async (): Promise<Showcase> => {
    const { data } = await axios.get("/showcase")

    return data[0]
}

export const createNewShowcase = async (questions: Array<ShowcaseQuestion>): Promise<Showcase> => {
    try {
        const response = await axios.post(
            `/showcase`,
            {
                questions,
            },
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        )

        if (!response.data) {
            throw new Error("Could not create showcase")
        }

        return response.data
    } catch (error) {
        throw new Error("Could not create showcase")
    }
}

export const updateShowcase = async (id: string, questions: Array<ShowcaseQuestion>): Promise<Showcase> => {
    try {
        const response = await axios.put(
            `/showcase/${id}`,
            {
                questions,
            },
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        )

        if (!response.data) {
            throw new Error("Could not update showcase")
        }

        return response.data
    } catch (error) {
        throw new Error("Could not update showcase")
    }
}

export const deleteShowcase = async (id: string): Promise<void> => {
    try {
        const response = await axios.delete(`/showcase/${id}`, {
            headers: {
                "Content-Type": "application/json",
            },
        })

        if (response.status !== 200) {
            throw new Error("Could not delete showcase")
        }
    } catch (error) {
        throw new Error("Could not delete showcase")
    }
}

export async function searchFiles({
    query,
    search_profile,
    file_format,
    file_source,
    date_from,
    date_to,
    file_size_from,
    file_size_to,
    // ai_tags,
    // manual_tags,
}: {
    query: string
    search_profile: string
    file_format: string[]
    file_source: string[]
    date_from: Date | null
    date_to: Date | null
    file_size_from: number
    file_size_to: number
    // ai_tags: string[]
    // manual_tags: string[]
}): Promise<any> {
    try {
        const response = await axios.post("/files/search_files", {
            query: query,
            search_profile: search_profile.toLowerCase(),
            file_format: file_format,
            file_source: file_source,
            date_from: date_from,
            date_to: date_to,
            file_size_from: file_size_from,
            file_size_to: file_size_to,
            // ai_tags: ai_tags,
            // manual_tags: manual_tags,
        })

        if (!response.data) {
            throw new Error("Could not get files")
        }

        return response.data
    } catch (error) {
        throw new Error("Could not get files")
    }
}

export async function searchConversations({
    query,
    search_profile,
    date_from,
    date_to,
}: {
    query: string
    search_profile: string
    date_from: Date | null
    date_to: Date | null
}): Promise<any> {
    try {
        const response = await axios.post("/chat/search_conversations", {
            query: query,
            search_profile: search_profile.toLowerCase(),
            date_from: date_from,
            date_to: date_to,
        })

        if (!response.data) {
            throw new Error("Could not get conversations")
        }

        return response.data
    } catch (error) {
        throw new Error("Could not get conversations")
    }
}
