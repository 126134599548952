import { isMobile } from "@/utils/mobileDetect"
import { useEffect } from "react"
import { useNavigate } from "react-router-dom"

export const MobileRedirect = () => {
    const navigate = useNavigate()

    useEffect(() => {
        if (isMobile()) {
            navigate("/mobile-oops")
        }
    }, [navigate])

    return null
}
