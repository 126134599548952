import { Link as RouterLink, LinkProps as RouterLinkProps } from "react-router-dom"
import { Link as MuiLink, LinkProps as MuiLinkProps } from "@mui/material"
import React from "react"

interface CustomLinkProps extends Omit<MuiLinkProps, "component"> {
    disabled?: boolean
    to: string
    state?: any
}

export const CustomLink: React.FC<CustomLinkProps> = ({ disabled, ...props }) => {
    if (disabled) {
        return (
            <MuiLink {...props} component="span" style={{ ...props.style, pointerEvents: "none", color: "inherit" }} />
        )
    }
    return <MuiLink {...props} component={RouterLink} />
}
