import { downloadFile, getFileInfo } from "@/api"
import { toast } from "react-toastify"
import { AxiosProgressEvent } from "axios"

export const onDownload = (path: string, fileId: string) => {
    const toastId = toast.loading("Downloading...0%")

    const file = getFileInfo(fileId, null)

    file.then(res => {
        const totalFileSize = res.file_size ? res.file_size : 0

        const progressEvent = (event: AxiosProgressEvent) => {
            toast.update(toastId, {
                render(res) {
                    return `Downloading...${Math.round((event.loaded / totalFileSize) * 100)}%`
                },
            })
        }

        const promise = downloadFile(fileId, progressEvent)

        promise.then(blob => {
            // create a blob url representing the data
            let url = window.URL.createObjectURL(new Blob([blob]))
            // create a link element
            let link = document.createElement("a")
            // set its href attribute
            link.href = url
            // set its download attribute (when used, the file downloaded will be called this name)
            link.setAttribute("download", path)
            // add disabled element to avoid weird behaviour
            link.style.display = "none"
            // add it to the document
            document.body.appendChild(link)
            // trigger the download by simulating click
            link.click()
            // remove the link from the dom
            link.remove()
            // remove the blob url from memory
            URL.revokeObjectURL(url)

            toast.done(toastId)
        })
    })
}
