import React from "react"
import { loginRequest } from "@/config/authConfig"
import { msalInstance } from "@/index"
import { AccountInfo, InteractionRequiredAuthError } from "@azure/msal-browser"
import { FallbackErrorComponent } from "@/components/Fallback/FallbackErrorComponent"
import { appInsights } from "@/telemetry/appInsights"

interface ErrorBoundaryProps {
    children: React.ReactNode
}

interface ErrorBoundaryState {
    hasError: boolean
    isRefreshing: boolean
}

const MAX_RETRIES = 10
const RETRY_TIMEFRAME = 30 * 60 * 1000 // 30 minutes

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
    state: ErrorBoundaryState = {
        hasError: false,
        isRefreshing: false,
    }

    refreshTimeout: ReturnType<typeof setTimeout> | null = null

    static getDerivedStateFromError(): Partial<ErrorBoundaryState> {
        return { hasError: true }
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        console.error("Error caught by ErrorBoundary:", error, errorInfo)

        // Log the error to ApplicationInsights
        appInsights.trackException({ exception: error, properties: errorInfo })

        const now = new Date().getTime()
        const lastRetryTime = parseInt(sessionStorage.getItem("lastRetryTime") ?? "0", 10)
        const retryCount = parseInt(sessionStorage.getItem("retryCount") ?? "0", 10)

        if (now - lastRetryTime < RETRY_TIMEFRAME) {
            if (retryCount < MAX_RETRIES) {
                sessionStorage.setItem("retryCount", (retryCount + 1).toString())
                sessionStorage.setItem("lastRetryTime", now.toString())

                if (this.refreshTimeout) {
                    clearTimeout(this.refreshTimeout)
                }
                this.refreshTimeout = setTimeout(this.handleAuthRefresh, retryCount * retryCount * 1000)
            } else {
                console.log("Max retries reached within the timeframe. Not handling error.")
                // Log this information to ApplicationInsights
                appInsights.trackEvent({ name: "ErrorBoundary_MaxRetriesReached" })
            }
        } else {
            sessionStorage.setItem("retryCount", "1")
            sessionStorage.setItem("lastRetryTime", now.toString())
            this.refreshTimeout = setTimeout(this.handleAuthRefresh, 1000)
        }
    }

    componentWillUnmount() {
        if (this.refreshTimeout) {
            clearTimeout(this.refreshTimeout)
        }
    }

    handleAuthRefresh = async () => {
        try {
            const accounts: AccountInfo[] = msalInstance.getAllAccounts()
            if (accounts && accounts.length > 0) {
                await msalInstance.acquireTokenSilent({
                    ...loginRequest,
                    account: accounts[0],
                })

                sessionStorage.setItem("retryCount", "0")
                sessionStorage.setItem("lastRetryTime", "0")
                this.setState({ hasError: false, isRefreshing: false })

                // Log successful auth refresh
                appInsights.trackEvent({ name: "ErrorBoundary_AuthRefreshSuccess" })
            } else {
                throw new InteractionRequiredAuthError("No accounts found in MSAL instance.")
            }
        } catch (authError) {
            console.error("Auth refresh or login redirect failed:", authError)
            // Log auth refresh failure
            appInsights.trackException({
                exception: authError as Error,
                properties: { context: "ErrorBoundary_AuthRefresh" },
            })

            const retryCount = parseInt(sessionStorage.getItem("retryCount") ?? "0", 10)
            if (retryCount >= MAX_RETRIES) {
                this.setState({ isRefreshing: false })
                // Log max retries reached
                appInsights.trackEvent({ name: "ErrorBoundary_MaxRetriesReached" })
            }
        }
    }

    render() {
        const retryCount = parseInt(sessionStorage.getItem("retryCount") ?? "0", 10)
        if (this.state.hasError) {
            // Adjust as needed for different rendering after max retries
            return retryCount < MAX_RETRIES ? <FallbackErrorComponent /> : <FallbackErrorComponent />
        }

        return this.props.children
    }
}
