import React, { CSSProperties } from "react"
import { Logo } from "@/assets"
import { Button, ThemeProvider } from "@mui/material"
import theme from "@/theme"

const styles = {
    container: {
        textAlign: "center" as CSSProperties["textAlign"],
    },
    logo: {
        width: "150px",
        marginTop: "30px",
        marginBottom: "-35px",
    },
    paragraph: {
        fontSize: "1.3rem",
        padding: "0 20px",
    },
}

export function FallbackErrorComponent() {
    return (
        <ThemeProvider theme={theme}>
            <div style={styles.container}>
                <img src={Logo} alt="logo" style={styles.logo} />
                <h2>Oops! Looks like our webpage is having a little meltdown.</h2>
                <p style={styles.paragraph}>
                    We apologize for the unexpected chaos and assure you that our tech team is working tirelessly to
                    clean up this digital mess.
                </p>
                <p style={styles.paragraph}>Please return to the homepage to continue using the application.</p>
                <Button color="primary" variant="contained" onClick={() => (window.location.href = "/")}>
                    Go to Homepage
                </Button>
            </div>
        </ThemeProvider>
    )
}
