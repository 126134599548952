import { NotificationCenterItem } from "react-toastify/addons/use-notification-center"
import { Id } from "react-toastify"

export interface SerializedNotification extends NotificationCenterItem {
    id: Id
    notification_text: string
    notification_type: string
    create_date: Date
    read: boolean
    content: string | any
    contentText: string
}

export const deserializeNotification = (serializedNotification: SerializedNotification): NotificationCenterItem => {
    if (
        !serializedNotification ||
        (!serializedNotification.notification_text && typeof serializedNotification.contentText !== "string")
    ) {
        console.error("Invalid serialized notification:", serializedNotification)
        return {
            id: "",
            read: false,
            createdAt: 0,
            status: "added",
            content: "Invalid notification data",
        }
    }
    const create_date = new Date(serializedNotification.create_date)
    const to_return = {
        ...serializedNotification,
        content: serializedNotification.notification_text || serializedNotification.contentText,
        createdAt: create_date.getTime(),
    }

    to_return.data = { ...to_return.data, addToNC: true }
    to_return.status = to_return.status || "added"

    return to_return
}
