import { useEffect, useRef } from "react"

// @ts-ignore
export const DynamicHtmlRenderer = ({ sourceHtml }) => {
    const containerRef = useRef(null)

    useEffect(() => {
        if (containerRef.current && sourceHtml) {
            // Parse the sourceHtml
            const parser = new DOMParser()
            const doc = parser.parseFromString(sourceHtml, "text/html")

            // Extract scripts
            const scripts = Array.from(doc.getElementsByTagName("script"))

            //remove all scripts
            scripts.forEach(script => {
                script.remove()
            })

            // Set the innerHTML of the container (without scripts)
            // @ts-ignore
            containerRef.current.innerHTML = doc.body.innerHTML

            // Function to load Google Charts and execute custom scripts
            const loadGoogleChartsAndExecuteScripts = () => {
                const googleChartsSrc = "https://www.gstatic.com/charts/loader.js"
                const existingGoogleChartsScript = document.querySelector(`script[src="${googleChartsSrc}"]`)

                function loadScript(scriptSrc: string) {
                    return new Promise((resolve, reject) => {
                        const script = document.createElement("script")
                        script.src = scriptSrc
                        script.onload = resolve

                        script.onerror = reject

                        if (!document.querySelector(`script[src="${googleChartsSrc}"]`)) {
                            document.head.appendChild(script)
                        }
                    })
                }

                if (!existingGoogleChartsScript) {
                    loadScript(googleChartsSrc)
                        .then(() => {
                            executeCustomScripts()
                        })
                        .catch(error => {
                            console.error("Failed to load Google Charts script:", error)
                        })
                } else {
                    executeCustomScripts()
                }

                function executeCustomScripts() {
                    //delay 1 sec
                    setTimeout(() => {
                        scripts.forEach(script => {
                            const googleChartsSrc = "https://www.gstatic.com/charts/loader.js"

                            const isGoogleChartsScript = script.src === googleChartsSrc

                            if (isGoogleChartsScript) {
                                return
                            }

                            const newScript = document.createElement("script")
                            Array.from(script.attributes).forEach(attr => {
                                newScript.setAttribute(attr.name, attr.value)
                            })

                            newScript.text = script.text
                            newScript.textContent = script.textContent
                            newScript.innerHTML = script.innerHTML
                            script.remove()

                            if (
                                !document.scripts[Symbol.iterator].toString().includes(newScript.text) &&
                                !document.head.innerHTML.includes(newScript.text)
                            ) {
                                document.head.appendChild(newScript)
                            } else {
                                const scriptFromHead = Array.from(document.scripts).find(s => s.text === newScript.text)

                                if (scriptFromHead) {
                                    scriptFromHead.remove()
                                    document.head.appendChild(newScript)
                                }
                            }
                        })
                    }, 500)
                }
            }

            // Load Google Charts and execute scripts
            loadGoogleChartsAndExecuteScripts()
        }
    }, [sourceHtml])

    return <div ref={containerRef} style={{ overflowY: "hidden", marginBottom: "8px" }} />
}
