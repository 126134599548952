// useFiles.ts
import { useMutation, useQuery, useQueryClient } from "react-query"
import { deleteFiles, getFiles, searchFiles } from "@/api"
import { toast } from "react-toastify"
import { FileWithPath } from "react-dropzone"
import { Dispatch, SetStateAction } from "react"
import { ICustomTags } from "@/pages/upload/Upload"
import { uploadFile } from "@/components/UploadToastContent/UploadToastContent"
import { useDispatch, useSelector } from "react-redux"
import {
    setAllDocuments,
    setChatLoading,
    setCurrentFolderPath,
    setDocs,
    setFilters,
    setSelectedDocuments,
} from "@/slices/chatSlice"

export const useFiles = ({ enabled = true }) => {
    const { docsSmartSearchActive, currentFolderPath } = useSelector((state: any) => state.chat)
    const dispatch = useDispatch()
    const cacheKey = "files"
    const allFilesCacheKey = "allFiles"
    const queryClient = useQueryClient()
    const shouldQueryRun = enabled && !docsSmartSearchActive
    const query = useQuery(cacheKey, () => getFiles(currentFolderPath), {
        enabled: shouldQueryRun,
        refetchInterval: shouldQueryRun ? 14000 : false,
    })

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const queryAllFiles = useQuery(allFilesCacheKey, () => getFiles("_all_files_"), {
        enabled: true,
        refetchInterval: 19000, // temp
        onSuccess: data => {
            dispatch(setAllDocuments(data))
        },
    })

    const invalidate = () => queryClient.invalidateQueries(cacheKey)

    const smartSearchFiles = useMutation(
        ({
            searchParameters,
            documentsSearchProfile,
            fileFormats,
            fileSources,
            documentsDateRange,
            fileSizeRange,
            // aiTags,
            // manualTags,
        }: {
            searchParameters: string
            documentsSearchProfile: string
            fileFormats: string[]
            fileSources: string[]
            documentsDateRange: (Date | null)[]
            fileSizeRange: [number, number]
            // aiTags: string[]
            // manualTags: string[]
        }) =>
            toast.promise(
                searchFiles({
                    query: searchParameters,
                    search_profile: documentsSearchProfile,
                    file_format: fileFormats,
                    file_source: fileSources,
                    date_from: documentsDateRange[0],
                    date_to: documentsDateRange[1],
                    file_size_from: fileSizeRange[0],
                    file_size_to: fileSizeRange[1],
                    // ai_tags: aiTags,
                    // manual_tags: manualTags,
                }),
                {
                    pending: "Searching files...",
                    // success: "Files searched successfully",
                    error: "Something went wrong",
                }
            ),
        {
            onSuccess: response => {
                // console.log("smartSearchFiles response", response)
                queryClient.setQueryData(cacheKey, response)
                dispatch(setChatLoading(false))
                dispatch(setSelectedDocuments(response.map((file: { id: number }) => file.id)))
            },
            onError: () => {
                dispatch(setChatLoading(false))
            },
        }
    )

    const openFileFolder = useMutation(
        ({ folder_name }: { folder_name: string }) =>
            toast.promise(getFiles(folder_name), {
                pending: `Opening folder ${folder_name}...`,
                error: "Something went wrong",
            }),
        {
            onSuccess: (response, { folder_name }) => {
                queryClient.setQueryData(cacheKey, response)
                dispatch(setChatLoading(false))
                dispatch(setDocs(response))
                dispatch(setFilters({ items: [] }))
                // dispatch(setDocsSmartSearchActive(true))
                dispatch(setCurrentFolderPath(folder_name))
                // dispatch(setSort([]))
                // console.log("response", response)
            },
            onError: (_, { folder_name }) => {
                dispatch(setChatLoading(false))
            },
        }
    )
    const deleteFile = useMutation(
        async ({ files }: { files: Array<string> }) => {
            return toast.promise(
                deleteFiles(files).then(response => {
                    if (response.status === 200 || response.status === 202) {
                        return response
                    } else {
                        throw new Error("Unexpected response status")
                    }
                }),
                {
                    pending: "Deleting file...",
                    success: {
                        render({ data }) {
                            return data.data?.message || "File(s) deleted successfully"
                        },
                    },
                    error: "Something went wrong",
                }
            )
        },
        {
            onSuccess: () => {
                invalidate()
            },
        }
    )

    const uploadFiles = useMutation(
        ({
            files,
            isUseDocumentIntelligence,
            userTags,
            isOverrideEnabled,
        }: {
            files: FileWithPath[]
            isUseDocumentIntelligence: boolean
            setFiles: Dispatch<SetStateAction<FileWithPath[]>>
            userTags: Array<ICustomTags>
            setTags: Dispatch<SetStateAction<Array<ICustomTags>>>
            isOverrideEnabled: boolean
        }) => uploadFile(files, isUseDocumentIntelligence, userTags, isOverrideEnabled),
        {
            onSuccess: (_, variables) => {
                variables.setFiles([])
                variables.setTags([])
            },
        }
    )

    return {
        ...query,
        queryClient,
        deleteFile,
        uploadFiles,
        smartSearchFiles,
        openFileFolder,
    }
}
