// useAzureWebPubSub.ts
import { useEffect, useRef, useState } from "react"
import { SendMessageError, WebPubSubClient } from "@azure/web-pubsub-client"
import axios from "axios"

interface NegotiateResponse {
    url: string
    hub: string
    user_id: string
}

export const enum HubNames {
    Notifications = "notifications",
    Chat = "chat",
}

async function handleWebPubSubEvents(client: WebPubSubClient, hubName: HubNames, isComponentMounted: boolean) {
    client.on("rejoin-group-failed", e => {
        console.log(`[WebPubSub][Hub:${hubName}] Rejoin group ${e.group} failed: ${e.error}`)
    })

    client.on("disconnected", async () => {
        console.log(`[WebPubSub][Hub:${hubName}] Connection disconnected`)
        if (client && isComponentMounted) {
            console.log(`[WebPubSub][Hub: ${hubName}] connection closed, reconnecting...`)
            await client.start()
        }
    })

    client.on("stopped", () => {
        console.log(`[WebPubSub][Hub:${hubName}] Client has stopped`)
    })
}

export const useAzureWebPubSub = (hubName: HubNames) => {
    const [pubSubClient, setPubSubClient] = useState<WebPubSubClient | null>(null)
    const [isClientConnected, setIsClientConnected] = useState<boolean>(false)
    let isComponentMounted = true

    const initWebPubSubRef = useRef<(() => Promise<void>) | null>(null)

    useEffect(() => {
        const initWebPubSub = async () => {
            try {
                const response = await axios.get("/notifications/client-access-url?hub=" + hubName)
                const data: NegotiateResponse = response.data

                const client: WebPubSubClient = new WebPubSubClient(data.url, { autoRejoinGroups: true })

                const groupName = `user_${data.user_id}`

                await client.start()
                await handleWebPubSubEvents(client, hubName, isComponentMounted)

                try {
                    await client.joinGroup(groupName)
                    console.log(`[WebPubSub][Hub:${hubName}] Joined group ${groupName}`)
                } catch (err) {
                    let id: string | null = null
                    if (err instanceof SendMessageError) {
                        if (err.ackId && typeof err.ackId === "string") {
                            id = err.ackId
                        }
                    }
                    if (id) {
                        await client.joinGroup(groupName, { ackId: id })
                    }
                }

                setPubSubClient(client)
                setIsClientConnected(true)
            } catch (error) {
                console.error(`[WebPubSub][Hub:${hubName}] Error initializing:${error}`)
            }
        }

        if (!pubSubClient || !isClientConnected) {
            initWebPubSubRef.current = initWebPubSub
        }
    }, [hubName])

    useEffect(() => {
        if (initWebPubSubRef.current) {
            initWebPubSubRef.current().then(() => {
                initWebPubSubRef.current = null
            })
        }

        return () => {
            isComponentMounted = false
            if (pubSubClient) {
                pubSubClient.stop()
                console.log(`[WebPubSub][Hub:${hubName}] Connection closed due to component unmount`)
            }
        }
    }, [pubSubClient])

    return { pubSubClient, isClientConnected }
}

export const useAzureWebPubSubNotifications = () => {
    const { pubSubClient, isClientConnected } = useAzureWebPubSub(HubNames.Notifications)
    return { notificationsPubSubClient: pubSubClient, isNotificationsClientConnected: isClientConnected }
}
