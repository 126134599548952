import React, { useEffect, useState } from "react"
import { Id, toast } from "react-toastify"
import { ICustomTags } from "@/pages/upload/Upload"
import { BlobServiceClient, BlockBlobClient, ContainerClient } from "@azure/storage-blob"
import axios from "axios"
import LinearProgressWithLabel from "../LinearProgressWithLabel"
import { Accordion, AccordionDetails, AccordionSummary, Alert, Box, Grid, Typography } from "@mui/material"
import { ExpandMore } from "@mui/icons-material"
import { v4 as uuid } from "uuid"
import * as pdfjs from "pdfjs-dist"
import { sumBy } from "lodash"
import JSZip from "jszip"
import { createNotification, NotificationData } from "@/components/NotificationCenter/useNotifications"

const MAX_RETRIES = 2 // Maximum number of upload attempts
const CONCURRENT_UPLOADS = 5 // Number of files to upload at once

interface SASContainerResponse {
    sas_token: string
    account_name: string
    container_name: string
}

type CreateTaskSchema = {
    file_name: string
    container_name: string
    file_size: number
    status: string
    user_tags?: ICustomTags[]
    number_of_pages_to_process: number
    task_group_id: string
    override: boolean
}

type UpdateTaskSchema = {
    id: string
    file_name: string
    status: string
}

interface UploadStatus {
    fileName: string
    status: "pending" | "uploaded" | "failed" | "uploading"
}

interface UploadSummaryToastProps {
    uploadStatusArray: UploadStatus[]
}

async function getNumberOfPages(file: File): Promise<number> {
    try {
        if (file.type === "application/pdf") {
            const array = await file.arrayBuffer()
            const loadingTask = pdfjs.getDocument(array)
            const pdfDocument = await loadingTask.promise
            const numPages = pdfDocument.numPages
            pdfDocument.destroy() // safe from memory leaks
            return numPages
        } else if (file.type === "application/vnd.openxmlformats-officedocument.presentationml.presentation") {
            return await getPptxSlideCount(file)
        } else {
            // It is not possible to accurately render .docx files for page counting in a frontend
            return 1
        }
    } catch (error) {
        console.error("Error reading file pages:", error)
        return 1
    }
}

async function getPptxSlideCount(file: File): Promise<number> {
    const arrayBuffer = await file.arrayBuffer()
    const zip = new JSZip()
    const content = await zip.loadAsync(arrayBuffer)
    const slideFiles = Object.keys(content.files).filter(f => f.startsWith("ppt/slides/slide"))
    return slideFiles.length
}

function uniqPrefix(len: number) {
    const chars = "abcdefghijklmnopqrstuvwxyz0123456789"
    return Array.from({ length: len }, () => chars[Math.floor(Math.random() * chars.length)]).join("") + "_"
}

async function uploadToBlob(
    file: File,
    index: number,
    startTime: number,
    blockBlobClient: BlockBlobClient,
    toastId: Id
) {
    // console.log(`Starting upload blob for ${file.name} with index ${index}`)

    const options = {
        blobHTTPHeaders: {
            blobContentType: file.type,
        },
        blockSize: 4 * 1024 * 1024, // 4MB
        concurrency: 3,
        maxSingleShotSize: 10 * 1024 * 1024, // 10MB
        onProgress: (ev: { loadedBytes: number }) => {
            const progress = (ev.loadedBytes / file.size) * 100
            const elapsed = (Date.now() - startTime) / 1000
            const estimatedTime = ((file.size / ev.loadedBytes) * elapsed - elapsed).toFixed().toString()

            toast.update(toastId, {
                render: () => (
                    <UploadToastContent
                        progress={progress}
                        elapsed={0}
                        estimatedTime={estimatedTime}
                        index={index}
                        fileName={file.name}
                    />
                ),
            })
        },
    }

    const res = await blockBlobClient.uploadData(file, options)
    if (res._response.status !== 201) {
        throw new Error(`Failed to upload file ${file.name}. Status Code: ${res._response.status}`)
    }
    toast.update(toastId, {
        render: () => (
            <UploadToastContent progress={100} elapsed={0} estimatedTime={"0"} index={index} fileName={file.name} />
        ),
    })
}

async function update_task(task: UpdateTaskSchema): Promise<string> {
    const url = `/tasks/update_task/${task.id}`

    const taskResponse = await axios.put(url, JSON.stringify(task), {
        headers: {
            "Content-Type": "application/json",
        },
    })

    if (taskResponse.status !== 200) {
        throw new Error(`Failed to update task for file ${task.file_name}`)
    }

    return taskResponse.data.id
}

const uploadSingleFile = async (
    file: File,
    index: number,
    isUseDocumentIntelligence: boolean,
    containerClient: ContainerClient,
    startTime: number,
    toastId: Id,
    taskId: string,
    uniqName: string
) => {
    console.log(`Starting upload for ${file.name} with index ${index}`)
    toast.update(toastId, {
        render: () => (
            <UploadToastContent progress={0} elapsed={0} estimatedTime={"0"} index={index} fileName={file.name} />
        ),
    })
    if (file.size <= 2) {
        // 2 bytes
        console.error(`File ${file.name} is empty. Skipping upload.`)
        throw new Error(`File upload failed for file ${file.name} as it is empty`)
    }

    let retries = 0
    let delay = 1000
    while (retries < MAX_RETRIES + 1) {
        try {
            const blockBlobClient = containerClient.getBlockBlobClient(uniqName)

            await uploadToBlob(file, index, startTime, blockBlobClient, toastId)
            toast.update(toastId, {
                render: () => (
                    <UploadToastContent
                        progress={100}
                        elapsed={(Date.now() - startTime) / 1000}
                        estimatedTime={"0"}
                        index={index}
                        fileName={file.name}
                    />
                ),
            })

            const updateTask: UpdateTaskSchema = {
                id: taskId,
                file_name: uniqName,
                status: "uploaded",
            }

            await update_task(updateTask)
            console.log(`Upload successful for ${file.name}`)
            break
        } catch (error) {
            console.error(`An error occurred during attempt ${retries + 1} for file ${file.name}:`, error)

            if (retries >= MAX_RETRIES) {
                throw new Error(`File upload failed for file ${file.name}`)
            }

            await new Promise(resolve => setTimeout(resolve, delay)) // wait for 'delay' ms
            delay *= 2
            retries++
        }
    }
}

export async function uploadFile(
    files: File[],
    isUseDocumentIntelligence: boolean,
    userTags: ICustomTags[],
    isOverrideEnabled: boolean
): Promise<boolean> {
    const handleBeforeUnload = (e: { preventDefault: () => void; returnValue: string }) => {
        e.preventDefault()
        e.returnValue = "You have unfinished uploads. Are you sure you want to leave?"
    }

    window.addEventListener("beforeunload", handleBeforeUnload)

    const toastId = uuid()
    const uploadStatusArray: UploadStatus[] = files.map(file => ({
        fileName: file.name,
        status: "pending" as const,
    }))

    try {
        const tableFileFormats = [
            "xls",
            "xlsx",
            "csv",
            "tsv",
            "ods",
            "xlsb",
            "xlsm",
            "xltx",
            "xltm",
            "xls",
            "xlt",
            "xml",
            "xlam",
            "xla",
            "xlw",
            "xlr",
        ]

        for (const file of files) {
            const fileExtension = file.name.split(".").pop()?.toLowerCase()
            const sizeInMB = file.size / 1024 / 1024

            if (tableFileFormats.includes(fileExtension!) && sizeInMB > 3) {
                toast.error(
                    "Excel files larger than 3MB are not supported. Please split the file into smaller parts and try again.",
                    {
                        toastId: toastId,
                        autoClose: false,
                        closeOnClick: false,
                    }
                )

                return false
            }
        }

        const pdfFileNamesWithoutExtension = files
            .filter(file => file.name.endsWith(".pdf"))
            .map(file => file.name.split(".").slice(0, -1).join("."))

        if (
            pdfFileNamesWithoutExtension.filter(
                name => tableFileFormats.filter(format => name.includes(`.${format}`)).length > 0
            ).length > 0
        ) {
            //show confirmation dialog
            const confirmation = window.confirm(
                "You are trying to upload one or more PDF files that appear to be Excel files. That may lead to a loss of answers quality. Do you want to proceed?"
            )

            if (!confirmation) {
                toast.error("Upload cancelled", {
                    toastId: toastId,
                    autoClose: false,
                    closeOnClick: false,
                })

                return false
            }
        }

        toast(
            <UploadToastContent elapsed={0} index={0} estimatedTime={"0"} progress={0} fileName={"Initializing..."} />,
            {
                toastId: toastId,
                autoClose: false,
                closeOnClick: false,
            }
        )

        const allFilesSize = files.reduce((acc, file) => acc + file.size, 0)

        const fileIndexToNumberOfPages = new Map<number, number>()

        for (const file of files) {
            const pageCount = await getNumberOfPages(file)

            // console.log(`Number of pages for ${file.name}: ${pageCount}`)
            // update toast notification to show "Initializing x/y files"
            toast.update(toastId, {
                render: (
                    <UploadToastContent
                        progress={0}
                        elapsed={0}
                        estimatedTime={`${~~((files.length - files.indexOf(file)) / 10)}`}
                        fileName={
                            "Initializing file " + (files.indexOf(file) + 1) + " of " + files.length + ": " + file.name
                        }
                        index={0}
                        allFilesNumber={files.length}
                    />
                ),
            })

            fileIndexToNumberOfPages.set(files.indexOf(file), pageCount)
        }
        // console.log("Number of pages for all files:", sumBy(Array.from(fileIndexToNumberOfPages.values())))

        // Get SAS token
        const response = await axios.get("/files/get_sas_token/add", {
            params: {
                files_size: allFilesSize,
                files_pages: sumBy(Array.from(fileIndexToNumberOfPages.values())),
            },
        })

        const data: SASContainerResponse = response.data
        const task_group_id = response.data.task_group_id

        const uniqNames: string[] = files.map((file, index) => uniqPrefix(5) + file.name)

        const tasks: CreateTaskSchema[] = files.map(file => {
            const index = files.indexOf(file)

            return {
                file_name: uniqNames[index],
                container_name: data.container_name,
                file_size: file.size,
                user_tags: userTags,
                status: "uploading",
                number_of_pages_to_process: fileIndexToNumberOfPages.get(index)!,
                override: isOverrideEnabled,
                task_group_id: task_group_id,
            }
        })

        toast.update(toastId, {
            render: (
                <UploadToastContent
                    progress={0}
                    elapsed={0}
                    estimatedTime={`0`}
                    fileName={"Initializing upload for " + files.length + " files"}
                    index={0}
                    allFilesNumber={files.length}
                />
            ),
        })

        // Send the array of tasks in one request
        const taskResponse = await axios.post("/tasks/create_tasks", JSON.stringify(tasks), {
            headers: {
                "Content-Type": "application/json",
            },
        })

        if (taskResponse.status !== 200) {
            throw new Error("Failed to create tasks")
        }

        const taskIds = taskResponse.data.map((res: any) => res.id) // Extract IDs from the response

        // Create BlobServiceClient
        const blobServiceClient = new BlobServiceClient(
            `https://${data.account_name}.blob.core.windows.net?${data.sas_token}`
        )

        const containerClient = blobServiceClient.getContainerClient(data.container_name)
        // Upload files with progress
        const startTime = Date.now()

        const uploadChunks = async (start: number, end: number) => {
            // Concurrently upload files by chunks
            const chunk = files.slice(start, end)
            const results = await Promise.allSettled(
                chunk.map((file, index) =>
                    uploadSingleFile(
                        file,
                        start + index,
                        isUseDocumentIntelligence,
                        containerClient,
                        startTime,
                        toastId,
                        taskIds[start + index],
                        uniqNames[start + index]
                    )
                )
            )

            // console.log(results);

            await Promise.allSettled(
                results.map(async (result, index) => {
                    if (result.status === "fulfilled") {
                        uploadStatusArray[start + index].status = "uploaded"
                    } else {
                        uploadStatusArray[start + index].status = "failed"
                        const updateFailedFile: UpdateTaskSchema = {
                            id: taskIds[start + index],
                            file_name: uniqNames[start + index],
                            status: "upload_failed",
                        }
                        await update_task(updateFailedFile)
                    }
                })
            )
        }

        for (let i = 0; i < files.length; i += CONCURRENT_UPLOADS) {
            await uploadChunks(i, i + CONCURRENT_UPLOADS)
        }

        const renderType = uploadStatusArray.some(item => item.status === "failed") ? "error" : "success"
        await create_notifications_persistent(uploadStatusArray)
        toast.update(toastId, {
            render: <UploadSummaryToast uploadStatusArray={uploadStatusArray} />,
            type: renderType,
            autoClose: renderType === "success" ? 3000 : false,
        })

        window.removeEventListener("beforeunload", handleBeforeUnload)

        return true
    } catch (error) {
        toast.dismiss(toastId)
        window.removeEventListener("beforeunload", handleBeforeUnload)
        throw new Error(`File upload failed: ${error}`)
    }
}

function create_notifications_persistent(uploadStatusArray: UploadStatus[]) {
    const failedFiles = uploadStatusArray.filter(item => item.status === "failed").map(item => item.fileName)
    const allUploaded = uploadStatusArray.filter(item => item.status === "uploaded")
    const createNotifications = () => {
        const uploadedFilesList = allUploaded.map(item => item.fileName)
        const failedFilesList = failedFiles

        let notificationText = ""
        let notificationType: "success" | "error" | "info" = "info"

        if (uploadedFilesList.length > 0 && failedFilesList.length === 0) {
            notificationText = `The following files have been successfully uploaded and added to the AI processing tool. They will appear on the <a href="/#/documents">Documents page</a> shortly:\n\n`
            notificationText += renderFileList(uploadedFilesList, "uploadedFiles")
            notificationType = "success"
        } else if (uploadedFilesList.length === 0 && failedFilesList.length > 0) {
            notificationText = `The following files failed to upload and need to be re-uploaded:\n\n`
            notificationText += renderFileList(failedFilesList, "failedFiles")
            notificationType = "error"
        } else if (uploadedFilesList.length > 0 && failedFilesList.length > 0) {
            notificationText = `Upload Summary:\n\nSuccessfully uploaded files:\n`
            notificationText += renderFileList(uploadedFilesList, "uploadedFiles")
            notificationText += "\nFailed files:\n"
            notificationText += renderFileList(failedFilesList, "failedFiles")
            notificationType = "error"
        }

        if (notificationText) {
            const newNotificationData: NotificationData = {
                notification_text: notificationText,
                type: notificationType,
                read: false,
                popup: false,
                create_date: new Date(),
            }
            createNotification(newNotificationData)
        }
    }

    const renderFileList = (fileList: string[], listId: string) => {
        if (fileList.length <= 3) {
            return fileList.join(", ")
        }

        const listItems = fileList.map(file => `<li>${file}</li>`).join("")
        return `<details><summary>${fileList.length} files</summary><ul>${listItems}</ul></details>`
    }

    createNotifications()
}

const UploadSummaryToast: React.FC<UploadSummaryToastProps> = ({ uploadStatusArray }) => {
    const failedFiles = uploadStatusArray.filter(item => item.status === "failed").map(item => item.fileName)
    const allUploaded = uploadStatusArray.filter(item => item.status === "uploaded")

    return (
        <div>
            <strong>Upload Summary</strong>
            {allUploaded.length > 0 && (
                <>
                    <p>
                        All files have been successfully uploaded and added to the AI processing tool. They will appear
                        on the <a href="/#/documents">Documents page</a> shortly.
                    </p>
                </>
            )}
            {failedFiles.length > 0 && (
                <>
                    <p style={{ color: "red" }}>The following files failed to upload and need to be re-uploaded:</p>
                    {failedFiles.map((file, index) => (
                        <li key={index}>{file}</li>
                    ))}
                </>
            )}
        </div>
    )
}

interface UploadToastContentProps {
    progress: number
    elapsed: number
    estimatedTime: string
    index: number
    fileName: string
    allFilesNumber?: number
}

export const UploadToastContent: React.FC<UploadToastContentProps> = props => {
    const [files, setFiles] = useState<Array<Omit<UploadToastContentProps, "index">>>([])
    const { progress, elapsed, estimatedTime, index, fileName, allFilesNumber } = props
    const [overallProgress, setOverallProgress] = useState(0)
    const [allFilesNumberState, setAllFilesNumberState] = useState(0)

    useEffect(() => {
        if (allFilesNumber) {
            setAllFilesNumberState(allFilesNumber)
        }
    }, [allFilesNumber])

    const OverallProgress = () => {
        return (
            <Box>
                <Typography>Overall Progress</Typography>
                <LinearProgressWithLabel
                    value={overallProgress}
                    color={overallProgress === 100 ? "success" : "secondary"}
                />
            </Box>
        )
    }

    useEffect(() => {
        if (allFilesNumberState > 0) {
            const readyFiles = files.filter(item => item.progress === 100)
            if (readyFiles.length > 1) {
                setOverallProgress((readyFiles.length / allFilesNumberState) * 100)
            }
        } else {
            setOverallProgress(0)
        }
    }, [files])

    useEffect(() => {
        if (files[index]) {
            files[index] = { progress, elapsed, estimatedTime, fileName }
            setFiles([...files])
        } else {
            setFiles([...files, { progress, elapsed, estimatedTime, fileName }])
        }
    }, [props])

    if (files.length === 0) {
        return (
            <Alert sx={{ mb: 1 }} severity="info">
                <Typography>Waiting for files to upload</Typography>
            </Alert>
        )
    }
    return (
        <Accordion
            sx={{ backgroundColor: "transparent", boxShadow: "none", maxHeight: "600px", overflowY: "auto" }}
            defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMore />}>
                <Box display="flex" flexDirection="column" flexGrow={1}>
                    <Typography>Uploading files</Typography>
                    {overallProgress > 0 && <OverallProgress />}
                </Box>
            </AccordionSummary>
            <AccordionDetails>
                <Box>
                    {files
                        .slice()
                        .reverse()
                        .map((item, index) => {
                            return (
                                <Grid key={index} sx={{ mb: 1 }}>
                                    <Grid item>
                                        <Typography>{item.fileName}</Typography>
                                        <Box sx={{ width: "100%" }}>
                                            {item.progress !== 0 && (
                                                <LinearProgressWithLabel
                                                    value={item.progress}
                                                    color={item.progress === 100 ? "success" : "secondary"}
                                                />
                                            )}
                                        </Box>
                                        {item.estimatedTime !== "0" && (
                                            <Typography>Estimate time: {item.estimatedTime} sec</Typography>
                                        )}
                                    </Grid>
                                </Grid>
                            )
                        })}
                </Box>
            </AccordionDetails>
        </Accordion>
    )
}
