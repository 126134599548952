import React from "react"
import { Container } from "@mui/material"

const innerPageIframeStyle: React.CSSProperties = {
    width: "100%",
    height: "100%",
    border: "none",
}

export const PrivacyPolicyInner: React.FC = () => {
    return (
        <Container
            sx={{
                p: "20px",
                flex: 1,
                height: "calc(100vh - 260px)",
            }}>
            <iframe
                style={innerPageIframeStyle}
                src="https://aidocr.com/legal/privacy-policy.html"
                title="Privacy Policy"></iframe>
        </Container>
    )
}

export const EULAInner: React.FC = () => {
    return (
        <Container
            sx={{
                p: "20px",
                flex: 1,
                height: "calc(100vh - 260px)",
            }}>
            <iframe
                style={innerPageIframeStyle}
                src="https://aidocr.com/legal/EULA.html"
                title="End User License Agreement"></iframe>
        </Container>
    )
}

export const DisclaimerInner: React.FC = () => {
    return (
        <Container
            sx={{
                p: "20px",
                flex: 1,
                height: "calc(100vh - 260px)",
            }}>
            <iframe
                style={innerPageIframeStyle}
                src="https://aidocr.com/legal/disclaimer.html"
                title="Disclaimer"></iframe>
        </Container>
    )
}

export const TermsOfUseInner: React.FC = () => {
    return (
        <Container
            sx={{
                p: "20px",
                flex: 1,
                height: "calc(100vh - 260px)",
            }}>
            <iframe
                style={innerPageIframeStyle}
                src="https://aidocr.com/legal/terms-of-use.html"
                title="Terms of Use"></iframe>
        </Container>
    )
}

const fullPageIframeStyle: React.CSSProperties = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    border: "none",
}

export const TermsOfUsePage: React.FC = () => {
    return (
        <iframe
            style={fullPageIframeStyle}
            src="https://aidocr.com/legal/terms-of-use.html"
            title="Terms of Use"></iframe>
    )
}

export const PrivacyPolicyPage: React.FC = () => {
    return (
        <iframe
            style={fullPageIframeStyle}
            src="https://aidocr.com/legal/privacy-policy.html"
            title="Privacy Policy"></iframe>
    )
}

export const DisclaimerPage: React.FC = () => {
    return (
        <iframe style={fullPageIframeStyle} src="https://aidocr.com/legal/disclaimer.html" title="Disclaimer"></iframe>
    )
}

export const EULAPage: React.FC = () => {
    return (
        <iframe
            style={fullPageIframeStyle}
            src="https://aidocr.com/legal/EULA.html"
            title="End User License Agreement"></iframe>
    )
}
