import { DynamicHtmlRenderer } from "@/components/Answer/DynamicHtmlRenderer"

function fixNumbersWithCommas(input: string) {
    // This regex matches numbers with commas as thousand separators
    const regex = /,(?=\d{3})/g

    // Replace the matched numbers by removing the commas
    return input.replace(regex, "")
}

export const renderChart = ({ chart, id }: { chart: string; id: string }) => {
    const is_mermaid = chart.includes("mermaid")

    if (is_mermaid) {
        const chart_formatted = chart.replace("mermaid", "").replaceAll("```", "").trim()

        return (
            <div className="mermaid" id={id} key={id}>
                {chart_formatted}
            </div>
        )
    } else {
        chart = chart.replace("```html", "").replace("```", "")

        const html = chart.match(/<!DOCTYPE html>[\s\S]*<\/html>/)

        if (html) {
            const regex = /var options = \{((?:[^{}]|\{(?:[^{}]|\{[^{}]*\})*\})*)\};/g

            chart = chart.replace(regex, (match, p1) => {
                if (!/backgroundColor\s*:\s*'/.test(p1)) {
                    if (p1.trim().endsWith(",")) {
                        return `var options = {${p1} backgroundColor: 'transparent'};`
                    } else {
                        return `var options = {${p1}, backgroundColor: 'transparent'};`
                    }
                }
                return match
            })

            chart = chart.replace(/(\{'packages':\s*)\}/, "$1['corechart']}")

            chart = fixNumbersWithCommas(chart)

            return (
                <div id={id} key={id}>
                    <DynamicHtmlRenderer sourceHtml={chart} />
                </div>
            )
        } else {
            return <></>
        }
    }
}
