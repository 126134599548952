// i18n.ts
import i18n from "i18next"
import { initReactI18next } from "react-i18next"
// Import all translations
import deTranslations from "@/locales/de.json"
import frTranslations from "@/locales/fr.json"
import esTranslations from "@/locales/es.json"
import itTranslations from "@/locales/it.json"
import svTranslations from "@/locales/sv.json"
import jaTranslations from "@/locales/ja.json"

// Define supported languages
const SUPPORTED_LANGUAGES = ["de", "fr", "es", "it", "sv", "ja"] as const
type SupportedLanguage = (typeof SUPPORTED_LANGUAGES)[number]

// Type for the resources object
type Resources = {
    [key in SupportedLanguage | "en"]: {
        translation: Record<string, string>
    }
}

// Create resources object with all translations
const resources: Resources = {
    en: { translation: {} }, // Assuming English is the default in the code
    de: { translation: deTranslations },
    fr: { translation: frTranslations },
    es: { translation: esTranslations },
    it: { translation: itTranslations },
    sv: { translation: svTranslations },
    ja: { translation: jaTranslations },
}

// Function to get saved language
export const getSavedLanguage = (): SupportedLanguage | "en" => {
    const savedLanguage = localStorage.getItem("googtrans")
    if (savedLanguage) {
        const cleanedLanguage = savedLanguage.replace("/auto/", "")
        return SUPPORTED_LANGUAGES.includes(cleanedLanguage as SupportedLanguage)
            ? (cleanedLanguage as SupportedLanguage)
            : "en"
    }
    return "en"
}

// Initialize i18n
export const initI18n = async () => {
    // console.time("i18n Initialization Time")

    const currentLanguage = getSavedLanguage()
    // console.log("[i18n] Current language:", currentLanguage)

    await i18n.use(initReactI18next).init({
        resources,
        fallbackLng: "en",
        lng: currentLanguage,
        supportedLngs: ["en", ...SUPPORTED_LANGUAGES],
        keySeparator: false,
        interpolation: {
            escapeValue: false,
        },
    })

    // console.timeEnd("i18n Initialization Time")
}

// Check if i18n is not initialized and initialize it
if (!i18n.isInitialized) {
    initI18n().catch(console.error)
}

export const t = (key: string): string => i18n.t(key)

export default i18n
