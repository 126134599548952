import React, { PropsWithChildren, useCallback } from "react";

import { useContext } from "react";

import Modals, { ModalsKeys } from "./Modals";
import { ModalContext } from "./ModalContextProvider";

export const ModalProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const { modal, setModal, props } = useContext(ModalContext);
    const close = useCallback(() => {
        setModal(ModalsKeys.None);
    }, []);

    return (
        <>
            {/*// @ts-ignore*/}
            {Object.keys(Modals).map(key => Modals[key] && React.createElement(Modals[key], { ...props, key: key, open: key === modal, close }))}
            {children}
        </>
    );
};
