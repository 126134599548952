import { Menu, MenuDivider, Popover, Position, TextDirection, ThemeContext, type Toggle } from "@react-pdf-viewer/core"
import { SelectionMode } from "@react-pdf-viewer/selection-mode"
import * as React from "react"
import { MoreIcon, ToolbarSlot } from "@react-pdf-viewer/toolbar"
import { Button, IconButton, Tooltip } from "@mui/material"

const PORTAL_OFFSET = { left: 0, top: 8 }

export const CustomMoreActionsPopover: React.FC<{
    toolbarSlot: ToolbarSlot
}> = ({ toolbarSlot }) => {
    const { direction } = React.useContext(ThemeContext)
    const portalPosition = direction === TextDirection.RightToLeft ? Position.BottomLeft : Position.BottomRight
    const {
        // DownloadMenuItem,
        EnterFullScreenMenuItem,
        GoToNextPageMenuItem,
        GoToPreviousPageMenuItem,
        OpenMenuItem,
        PrintMenuItem,
        RotateBackwardMenuItem,
        RotateForwardMenuItem,
        SwitchSelectionModeMenuItem,
        SwitchThemeMenuItem,
    } = toolbarSlot

    const renderTarget = (toggle: Toggle, opened: boolean): React.ReactElement => {
        return (
            <Tooltip title="More actions">
                <IconButton onClick={() => toggle()}>
                    <MoreIcon />
                </IconButton>
            </Tooltip>
        )
    }

    const renderContent = (toggle: Toggle): React.ReactElement => {
        return (
            <Menu>
                {/* These items will be hidden on the larger screens */}
                <div className="rpv-core__display--block rpv-core__display--hidden-medium">
                    <SwitchThemeMenuItem onClick={toggle} />
                </div>
                <div className="rpv-core__display--block rpv-core__display--hidden-medium">
                    <EnterFullScreenMenuItem onClick={toggle} />
                </div>
                <div className="rpv-core__display--block rpv-core__display--hidden-medium">
                    <OpenMenuItem />
                </div>
                <div className="rpv-core__display--block rpv-core__display--hidden-medium">
                    <PrintMenuItem onClick={toggle} />
                </div>
                {/*<div className="rpv-core__display--block rpv-core__display--hidden-medium">*/}
                {/*    <DownloadMenuItem onClick={toggle} />*/}
                {/*</div>*/}

                <div className="rpv-core__display--block rpv-core__display--hidden-medium">
                    <GoToPreviousPageMenuItem onClick={toggle} />
                </div>
                <div className="rpv-core__display--block rpv-core__display--hidden-medium">
                    <GoToNextPageMenuItem onClick={toggle} />
                </div>

                <EnterFullScreenMenuItem onClick={toggle} />
                {/*<DownloadMenuItem onClick={toggle} />*/}
                <MenuDivider />
                <RotateForwardMenuItem onClick={toggle} />
                <RotateBackwardMenuItem onClick={toggle} />
                <MenuDivider />
                <SwitchSelectionModeMenuItem mode={SelectionMode.Text} onClick={toggle} />
                <SwitchSelectionModeMenuItem mode={SelectionMode.Hand} onClick={toggle} />
            </Menu>
        )
    }

    return (
        <Popover
            ariaControlsSuffix="toolbar-more-actions"
            ariaHasPopup="menu"
            position={portalPosition}
            target={renderTarget}
            content={renderContent}
            offset={PORTAL_OFFSET}
            closeOnClickOutside={true}
            closeOnEscape={true}
        />
    )
}
