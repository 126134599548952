import React, { FC, MouseEvent, useEffect, useState } from "react"

import { Avatar, Box, Button, Divider, IconButton, Paper, SxProps, Theme, Typography } from "@mui/material"

import { User } from "@/assets"
import UserMenu from "@/components/UserMenu"
import NotificationCenter from "@/components/NotificationCenter"
import { useLocation } from "react-router-dom"
import { useIsAuthenticated, useMsal } from "@azure/msal-react"
import { useSelector } from "react-redux"
import "@/components/Translate/google-translate.css"
import { googleTranslateConfig, loadGoogleTranslate } from "@/components/Translate/google-translate"
import { LanguageSelector } from "@/components/Translate/Translate"
import { t } from "i18next"
import { Menu } from "@mui/icons-material"

interface IHeaderProps {
    isMobileOpen: boolean
    setMobileOpen: (state: boolean) => void
}

export const styles: Record<string, SxProps<Theme>> = {
    container: {
        padding: "10px 20px",
        backgroundColor: "#FBFBFB",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        zIndex: 1,
        borderRadius: "0px",
    },
    buttonGroup: {
        display: "flex",
        alignItems: "center",
        gap: "15px",
    },
    button: {
        color: "#6C6C6C",
        fontSize: "16px",
    },
}

declare global {
    interface Window {
        googleTranslateElementInit: () => void
    }
}
const useHeaderTitle = () => {
    const { pathname } = useLocation()
    const [title, setTitle] = useState<string>("Dashboard")
    const chatTitle = useSelector((state: any) => state.chat.chatTitle)

    useEffect(() => {
        const pathSegments = pathname.split("/")
        switch (pathSegments[1]) {
            case "conversations":
                setTitle("Conversations")
                break
            case "workflows":
                setTitle("Workflows")
                break
            case "subscriptions":
                setTitle("Subscription Plans")
                break
            case "chat":
                if (chatTitle.length > 1) {
                    setTitle(chatTitle)
                } else {
                    setTitle("Chat")
                }
                break
            case "documents":
                if (pathSegments.length > 2 && pathSegments[2]) {
                    setTitle("Document details")
                } else {
                    setTitle("Documents")
                }
                break
            case "upload":
                setTitle("Upload")
                break
            case "settings":
                setTitle("Settings")
                break
            case "user":
                setTitle("My account")
                break
            case "account":
                setTitle("Account")
                break
            case "connectors":
                setTitle("Connectors")
                break
            case "terms-of-use-inner":
                setTitle("Terms of Use")
                break
            case "privacy-policy-inner":
                setTitle("Privacy Policy")
                break
            case "eula-inner":
                setTitle("End User License Agreement")
                break
            case "disclaimer-inner":
                setTitle("Disclaimer")
                break
            case "help-and-resources":
                setTitle("Help & Resources")
                break
            case "announcements":
                setTitle("Announcements")
                break
            case "workflow-details":
                setTitle("Workflow details")
                break
            default:
                setTitle("Dashboard")
        }
    }, [pathname])

    return title
}

export function stringToColor(string: string) {
    let hash = 0
    let i

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash)
    }

    let color = "#"

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff
        color += `00${value.toString(16)}`.slice(-2)
    }

    return color
}

export function stringAvatar(name: string) {
    return {
        sx: {
            bgcolor: stringToColor(name),
        },
        className: "notranslate",
        children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
    }
}

export const Header: FC<IHeaderProps> = ({ setMobileOpen, isMobileOpen }) => {
    const title = useHeaderTitle()
    const isAuthenticated = useIsAuthenticated()
    const { accounts } = useMsal()
    // const navigate = useNavigate()
    const { status, planName } = useSelector((state: any) => state.chat.subscriptionPlanStatus)
    const isStatusDefined = status !== undefined
    const isPlanNameDefined = planName !== undefined
    const isEligibleForUpgrade = status !== "active" || planName === "Freemium"
    const isProduction = import.meta.env.VITE_ENVIRONMENT_NAME === "production"

    const showUpgradeButton = isStatusDefined && isPlanNameDefined && isEligibleForUpgrade

    useEffect(() => {
        let scriptLoaded = false
        let initAttempts = 0
        const maxAttempts = 10

        const initGoogleTranslate = () => {
            if (window.google && window.google.translate && window.google.translate.TranslateElement) {
                new window.google.translate.TranslateElement(
                    {
                        pageLanguage: googleTranslateConfig.lang,
                        includedLanguages: "en,de,fr,it,es", // Customize this list as needed
                        layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE,
                    },
                    "google_translate_element"
                )
                scriptLoaded = true
            } else if (initAttempts < maxAttempts) {
                initAttempts++
                setTimeout(initGoogleTranslate, 10 * initAttempts ** 2)
            } else {
                console.error("Failed to initialize Google Translate after multiple attempts")
            }
        }

        loadGoogleTranslate(() => {
            // console.log("Google Translate script loaded")
            initGoogleTranslate()
        })

        return () => {
            if (scriptLoaded) {
                const script = document.getElementById("google-translate-script")
                if (script) {
                    script.remove()
                }
            }
        }
    }, [])

    const handleDrawerToggle = () => {
        setMobileOpen(!isMobileOpen)
    }

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)
    const handleClick = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    let givenName = null
    let familyName = null

    const tokenClaims = accounts[0]?.idTokenClaims

    if (tokenClaims != null) {
        givenName = tokenClaims.given_name ? (tokenClaims.given_name as string).toUpperCase() : null
        familyName = tokenClaims.family_name ? (tokenClaims.family_name as string).toUpperCase() : null
    }

    return (
        <Paper component={"header"} sx={styles.container}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={handleDrawerToggle}
                    sx={{ mr: 2, display: { md: "none" } }}>
                    <Menu />
                </IconButton>

                <Typography className={"notranslate"} variant="h1">
                    {t(title)}
                </Typography>
            </Box>
            <Box sx={styles.buttonGroup}>
                {/*<Button*/}
                {/*    variant="contained"*/}
                {/*    onClick={() => navigate("/subscriptions")}*/}
                {/*    sx={{ fontColor: "white", backgroundColor: "#6AAFE3" }}>*/}
                {/*    Upgrade plan*/}
                {/*</Button>*/}
                <Divider orientation="vertical" variant="middle" flexItem />
                <LanguageSelector />
                <Divider orientation="vertical" variant="middle" flexItem />

                <NotificationCenter />
                <Divider orientation="vertical" variant="middle" flexItem />
                <Avatar className="notranslate" sx={{ backgroundColor: "#fff" }}>
                    <Button sx={{ width: "100%", minWidth: "36px", padding: 0 }} onClick={handleClick}>
                        {isAuthenticated ? (
                            <Avatar {...stringAvatar(`${givenName} ${familyName}`)} />
                        ) : (
                            <img style={{ width: "100%" }} src={User} alt="User" />
                        )}
                    </Button>
                </Avatar>
                <UserMenu anchorEl={anchorEl} handleClose={handleClose} open={open} />
            </Box>
        </Paper>
    )
}
