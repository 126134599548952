import React from "react"
import { Button, Checkbox, Dialog, DialogContent, DialogTitle, FormControlLabel, IconButton } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import { ModalProps } from "./index"
import { useSettings } from "@/components/Settings/useSettings"
import "./welcome.css"

interface WelcomeModalProps extends ModalProps {
    onDoNotShowAgainChange?: (doNotShowAgain: boolean) => void
}

const WelcomeModal: React.FC<WelcomeModalProps> = ({ open, close, title, content }) => {
    const { showWelcomeMessage, onShowWelcomeMessageChange } = useSettings()

    const handleDoNotShowAgainChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onShowWelcomeMessageChange(!event.target.checked)
    }

    return (
        <Dialog open={open} onClose={close} fullWidth={true} maxWidth={"sm"} className="welcome-modal">
            <DialogTitle
                sx={{
                    backgroundColor: "white",
                    padding: "12px 18px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}>
                {content}
                <IconButton onClick={close}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{ overflowY: "hidden", overflowX: "hidden" }}>
                <div
                    style={{
                        width: "550px",
                        height: "740px",
                        overflow: "hidden",
                        position: "relative",
                        marginTop: "-90px",
                        marginBottom: "-75px",
                    }}>
                    <iframe
                        src="https://www.youtube.com/embed/x9xsvRd9Ir8?controls=0&autoplay=0&loop=1"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen></iframe>
                </div>
                <div style={{ display: "flex", justifyContent: "center", marginTop: "0px" }}>
                    <Button href="/#/help-and-resources" variant="contained" target="_blank">
                        Help and resources
                    </Button>
                </div>
                <div style={{ display: "flex", justifyContent: "center", marginTop: "15px", marginBottom: "-5px" }}>
                    <FormControlLabel
                        control={<Checkbox checked={!showWelcomeMessage} onChange={handleDoNotShowAgainChange} />}
                        label="Do not show this message again"
                    />
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default WelcomeModal
