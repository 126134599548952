export const isMobile = (): boolean => {
    const userAgent = typeof navigator === "undefined" ? "" : navigator.userAgent

    const mobileDevices = [
        /Android/,
        /webOS/,
        /iPhone/,
        /iPad/,
        /iPod/,
        /BlackBerry/,
        /IEMobile/,
        /Opera Mini/,
        /Windows Phone/,
    ]

    const isMobileDevice = mobileDevices.some(pattern => pattern.test(userAgent))
    const isSmallScreen = window.innerWidth < 768

    if (isMobileDevice && isSmallScreen) {
        console.log(`Mobile device detected: ${userAgent}`)
        return true
    }
    return false
}
