import React, { ReactNode, useEffect, useRef, useState } from "react"
import axios from "axios"
import { SerializedNotification } from "./NotificationSerialization"
import { useNotificationCenter } from "react-toastify/addons/use-notification-center"
import { CustomNotificationCenterItem } from "@/components/NotificationCenter/NotificationCenter"
import { toast } from "react-toastify"
import DOMPurify from "dompurify"

export interface NotificationData {
    notification_text: string
    type: string
    read?: boolean
    create_date?: Date
    popup?: boolean
}

export const createNotification = async (notificationData: NotificationData) => {
    try {
        await axios.post("/notifications/create-notification", notificationData)
    } catch (error) {
        console.error("Error creating notification:", error)
    }
}

export const useNotifications = () => {
    const [notificationsData, setNotifications] = useState<SerializedNotification[]>([])
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        const loadNotifications = async () => {
            try {
                setIsLoading(true)
                const response = await axios.get("/notifications")
                const data = response.data
                setNotifications(data)
            } catch (error) {
                console.error("Error loading notifications:", error)
            } finally {
                setIsLoading(false)
            }
        }

        loadNotifications()
    }, [])

    return { notificationsData, isLoading }
}

export const useAddUpdateNotification = () => {
    const { add, notifications, clear, update, find } = useNotificationCenter()
    const notificationsMapRef = useRef(new Map())

    const add_update_notification = (notification: CustomNotificationCenterItem) => {
        const notificationsMap = notificationsMapRef.current

        if (notification.content && typeof notification.content === "string") {
            if (/<[a-z][\s\S]*>/i.test(notification.content)) {
                const sanitizedContent = DOMPurify.sanitize(notification.content)
                notification.content = [
                    React.createElement("div", {
                        dangerouslySetInnerHTML: { __html: sanitizedContent },
                    }) as ReactNode,
                ]
            }
        }

        const id = notification.id
        const existingNotification = notificationsMap.get(id)
        const notificationInCenter = find(id)

        if (existingNotification || notificationInCenter) {
            notificationsMap.set(id, notification)
            update(id, notification)
        } else {
            notificationsMap.set(id, notification)
            add(notification)
            if (notification.popup) {
                const toastContent = Array.isArray(notification.content)
                    ? notification.content[0]
                    : notification.content

                // @ts-ignore
                toast(toastContent, { type: notification.type, ToastId: id })
            }
        }
    }

    return {
        add_update_notification,
        notifications,
        find,
        update,
        add,
        clear,
    }
}
