import axios from "axios"
import { getSavedLanguage } from "@/i18n"
import { toast } from "react-toastify"
import React, { useRef } from "react"
import { v4 as uuid } from "uuid"
import { useModal } from "@/hooks/useModal"
import { t } from "i18next"

const targetLanguageCode = getSavedLanguage()

const languageMap = {
    de: "German",
    fr: "French",
    es: "Spanish",
    it: "Italian",
    sv: "Swedish",
    en: "English",
    ja: "Japanese",
}

export const targetLanguageReadable = languageMap[targetLanguageCode] || "Your language"

interface TranslateParams {
    id: string | undefined
    currentPage: React.MutableRefObject<number>
    setIsTranslating: React.Dispatch<React.SetStateAction<boolean>>
    currentLanguage: string
    setFileUrl: React.Dispatch<React.SetStateAction<string>>
    originalFileUrl: string
    setFileNameCurrent: React.Dispatch<React.SetStateAction<string>>
    fileName: string
    setCurrentLanguage: React.Dispatch<React.SetStateAction<string>>
    setPage: React.Dispatch<React.SetStateAction<number>>
    originalPage: React.MutableRefObject<number>
    setIsFullFileTranslated: React.Dispatch<React.SetStateAction<boolean>>
    totalPages: number
    targetLanguageCode: string
    targetLanguageReadable: string
}

export function handleTranslatePDF(params: TranslateParams) {
    const { confirm } = useModal()
    const isConfirmingFullTranslation = useRef(false)
    const forceLanguageTranslation = useRef(false)

    const resetToOriginal = () => {
        console.log("resetToOriginal")
        const {
            setFileUrl,
            originalFileUrl,
            setFileNameCurrent,
            fileName,
            setIsTranslating,
            setCurrentLanguage,
            setPage,
            originalPage,
            setIsFullFileTranslated,
        } = params
        setFileUrl(originalFileUrl)
        setFileNameCurrent(fileName)
        setIsTranslating(false)
        setCurrentLanguage("original")
        setPage(originalPage.current)
        setIsFullFileTranslated(false)
    }

    const getEndpoint = (action: "page" | "full") => {
        const { id, currentPage, totalPages, targetLanguageCode } = params
        const pageToTranslate = action === "page" ? currentPage.current + 1 : undefined
        return action === "page"
            ? `/files/download_translated_page/${id}/${pageToTranslate}/${totalPages}/${targetLanguageCode}`
            : `/files/download_translated/${id}/${totalPages}/${targetLanguageCode}`
    }

    const fetchTranslation = async (action: "page" | "full") => {
        const endpoint = getEndpoint(action)
        return await axios.get(endpoint, {
            responseType: "arraybuffer",
            params: {
                force_translate: forceLanguageTranslation.current,
                force_translate_full: isConfirmingFullTranslation.current,
            },
            timeout: action === "page" ? 120000 : 300000,
        })
    }

    const handleSuccessfulTranslation = (data: ArrayBuffer, action: "page" | "full") => {
        const {
            setPage,
            setFileUrl,
            setFileNameCurrent,
            fileName,
            setCurrentLanguage,
            setIsFullFileTranslated,
            targetLanguageCode,
            currentPage,
        } = params
        const blob = new Blob([data], { type: "application/pdf" })
        const pageToTranslate = action === "page" ? currentPage.current + 1 : undefined
        setPage(0)
        setFileUrl(URL.createObjectURL(blob))
        setFileNameCurrent(
            action === "page"
                ? `${fileName.replace(".pdf", "")}_page_${pageToTranslate}_${targetLanguageCode}.pdf`
                : `${fileName.replace(".pdf", "")}_${targetLanguageCode}.pdf`
        )
        setCurrentLanguage("translated")
        setIsFullFileTranslated(action === "full")
    }

    const handleTranslationError = (error: any) => {
        if (error?.response?.status === 402) {
            isConfirmingFullTranslation.current = false
            forceLanguageTranslation.current = false
            toast.error("Translation limit reached. Please upgrade your plan and contact sales at sales@aidocr.com", {
                toastId: uuid(),
                autoClose: false,
                closeOnClick: false,
            })
        } else {
            isConfirmingFullTranslation.current = false
            forceLanguageTranslation.current = false
            toast.error(`Translation failed: ${error?.message}`)
        }
    }

    const handleFullFileTranslationConfirmation = () => {
        return new Promise<void>(resolve => {
            confirm(t("Are you sure you want to translate all {{num}} pages?", { num: params.totalPages }), {
                onOk: () => {
                    isConfirmingFullTranslation.current = true
                    handleTranslate("full")
                    resolve()
                },
                onCancel: () => {
                    isConfirmingFullTranslation.current = false
                    forceLanguageTranslation.current = false
                    params.setIsTranslating(false)
                },
            })
        })
    }
    const handleSameLanguageTranslation = (action: "page" | "full") => {
        return new Promise<void>(resolve => {
            confirm(
                t(
                    "This file is already in {{targetLanguageReadable}}. Do you still want to proceed with the translation?",
                    { targetLanguageReadable }
                ),
                {
                    onOk: () => {
                        forceLanguageTranslation.current = true
                        handleTranslate(action)
                        resolve()
                    },
                    onCancel: () => {
                        isConfirmingFullTranslation.current = false
                        forceLanguageTranslation.current = false
                        params.setIsTranslating(false)
                    },
                }
            )
        })
    }
    const handleTranslate = async (action: "page" | "full") => {
        const { id, currentPage, setIsTranslating, currentLanguage, originalPage } = params

        if (!id || (action === "page" && currentPage.current === undefined)) {
            console.error(`Invalid ID or page number for translation: ${id}, ${currentPage.current}`)
            return
        }

        setIsTranslating(true)

        if (currentLanguage !== "original") {
            resetToOriginal()
            return
        }

        originalPage.current = currentPage.current

        try {
            const response = await fetchTranslation(action)
            const contentType = response.headers["content-type"]
            if (contentType && contentType.includes("application/json")) {
                const decoder = new TextDecoder("utf-8")
                const jsonString = decoder.decode(response.data)
                const jsonData = JSON.parse(jsonString)
                if (response.status === 200 && jsonData?.detail === "Are you sure you want to translate all pages?") {
                    setIsTranslating(true)
                    await handleFullFileTranslationConfirmation()
                    return
                }
                if (response.status === 200 && jsonData?.detail === "File is already in the target language") {
                    setIsTranslating(true)
                    await handleSameLanguageTranslation(action)
                    return
                }
            }

            if (response.status !== 200) {
                throw new Error("Failed to fetch translated file")
            }
            const asArrayBuffer = response.data as ArrayBuffer
            handleSuccessfulTranslation(asArrayBuffer, action)
            setIsTranslating(false)
            isConfirmingFullTranslation.current = false
            forceLanguageTranslation.current = false
        } catch (error: any) {
            handleTranslationError(error)
            setIsTranslating(false)
        }
    }

    return handleTranslate
}
