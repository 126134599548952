import axios from "axios"
import { AskResponse } from "@/api/models"
import { fetchEventSource } from "@microsoft/fetch-event-source"
import { getValidAccessToken } from "@/utils/accessToken"
import { setChatError, setChatLoading } from "@/slices/chatSlice"
import { Dispatch } from "react"

export type Conversation = {
    _id: string
    title: string
    // created_by_user_id: string
    // created_time_utc: Date
    updated_time_utc: Date
    is_pin: boolean
}

export const getConversationsList = async (): Promise<Array<Conversation>> => {
    try {
        const response = await axios.get(`/chat`)

        if (!response.data) {
            throw new Error("Could not get conversations")
        }

        return response.data
    } catch (error) {
        throw new Error("Could not get conversations")
    }
}

export const getConversation = async (id: string): Promise<AskResponse> => {
    try {
        const response = await axios.get(`/chat/${id}/`)

        if (!response.data) {
            throw new Error("Could not get conversation")
        }

        return response.data
    } catch (error) {
        throw new Error("Could not get conversation")
    }
}
export const removeQAPair = async (
    chatId: string,
    index: number,
    history_id: string | undefined,
    questionText: string,
    remove_conversation_if_first: boolean
): Promise<void> => {
    try {
        await axios.delete(`/chat/${chatId}/remove_qa_pair`, {
            data: { index, question_text: questionText, remove_conversation_if_first, history_id },
        })
    } catch (error) {
        throw new Error("Could not remove Q&A pair")
    }
}

export const setConversation = async (
    dispatch: Dispatch<any>,
    params: any,
    onStreamingProgress?: (progressEvent: { chat_id: any; answer: string }) => void,
    onStreamingEnd?: () => void
): Promise<any> => {
    try {
        const accessToken = await getValidAccessToken()

        const handleError = (status: number): void => {
            let errorMsg = ""
            if (status === 402) {
                errorMsg =
                    "Could not continue conversation. Please upgrade your plan and contact sales at <a href='mailto:sales@aidocr.com'>sales@aidocr.com</a>"
            } else if (status === 422) {
                errorMsg = "Could not continue conversation, please verify your question."
            } else {
                errorMsg = "Could not continue conversation, please start a new conversation or try later."
            }
            dispatch(setChatError(errorMsg))
            dispatch(setChatLoading(false))
            onStreamingEnd?.()
        }

        let response = null
        let hasError = false

        await fetchEventSource("/chat", {
            method: "POST",
            body: JSON.stringify(params),
            openWhenHidden: true,
            headers: {
                Accept: "text/event-stream",
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
            onopen(res): Promise<void> {
                if (res.ok && res.status === 200) {
                    console.log("Connection made ", res)
                } else {
                    // if (res.status >= 400 && res.status < 500 && res.status !== 429)
                    console.log("Client-side error ", res)
                    handleError(res.status)
                    hasError = true
                }
                return Promise.resolve()
            },
            onmessage(event) {
                if (hasError) return
                const data = JSON.parse(event.data)
                onStreamingProgress?.(data)
                response = data
            },
            onclose() {
                if (!hasError) {
                    onStreamingEnd?.()
                    console.log("Connection closed by the server")
                }
            },
            onerror(err) {
                if (!hasError) {
                    hasError = true
                    handleError(500)
                    console.log("There was an error from server", err)
                }
            },
        })

        return response
    } catch (error: any) {
        console.log("SendMsg error:", error)
        dispatch(setChatError("Could not continue conversation, please start a new conversation or try later."))
        dispatch(setChatLoading(false))
        onStreamingEnd?.()
        return null
    }
}

export const stopGeneration = async (id: string): Promise<string> => {
    try {
        const response = await axios.post(`/chat/${id}/stop_generation`)

        if (response.status !== 200) {
            throw new Error("Could not stop answer generation")
        }

        return response.data
    } catch (error) {
        throw new Error("Could not stop answer generation")
    }
}

export const deleteConversation = async (ids: Array<string>): Promise<string> => {
    try {
        const response = await axios.delete("/chat/delete", { data: ids })

        if (response.status !== 200) {
            throw new Error("Could not delete conversation")
        }

        return response.data
    } catch (error) {
        throw new Error("Could not delete conversation")
    }
}

export const exportConversation = async (id: string): Promise<{ data: Blob; filename: string }> => {
    try {
        const response = await axios.get(`/chat/${id}/export`, { responseType: "blob" })

        if (!response.data || !response.headers["content-disposition"]) {
            throw new Error("Could not export conversation")
        }

        const contentDisposition = response.headers["content-disposition"]
        const filename = contentDisposition.split("filename=")[1].replace(/['"]/g, "")

        return { data: response.data, filename }
    } catch (error) {
        throw new Error("Could not export conversation")
    }
}

export const updateConversationTitle = async (id: string, title: string): Promise<void> => {
    try {
        await axios.patch(`/chat/${id}/update_title`, { new_title: title })
    } catch (error) {
        throw new Error("Could not update conversation title")
    }
}

export const togglePinConversation = async (chatId: string): Promise<void> => {
    try {
        await axios.patch(`/chat/${chatId}/toggle_pin`)
    } catch (error) {
        throw new Error("Could not toggle pin status")
    }
}
